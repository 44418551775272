/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createMerchant = /* GraphQL */ `mutation CreateMerchant($newMerchant: MerchantCreateInput!) {
  createMerchant(newMerchant: $newMerchant) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMerchantMutationVariables,
  APITypes.CreateMerchantMutation
>;
export const updateMerchant = /* GraphQL */ `mutation UpdateMerchant($updateMerchant: MerchantUpdateInput!) {
  updateMerchant(updateMerchant: $updateMerchant)
}
` as GeneratedMutation<
  APITypes.UpdateMerchantMutationVariables,
  APITypes.UpdateMerchantMutation
>;
export const revokeAccessToken = /* GraphQL */ `mutation RevokeAccessToken($merchantId: ID!) {
  revokeAccessToken(merchantId: $merchantId)
}
` as GeneratedMutation<
  APITypes.RevokeAccessTokenMutationVariables,
  APITypes.RevokeAccessTokenMutation
>;
export const updateMerchantGoogleReviewUrl = /* GraphQL */ `mutation UpdateMerchantGoogleReviewUrl(
  $updateMerchant: MerchantUpdateGoogleReviewUrl!
) {
  updateMerchantGoogleReviewUrl(updateMerchant: $updateMerchant)
}
` as GeneratedMutation<
  APITypes.UpdateMerchantGoogleReviewUrlMutationVariables,
  APITypes.UpdateMerchantGoogleReviewUrlMutation
>;
export const createCategory = /* GraphQL */ `mutation CreateCategory($newCategory: CategoryCreateInput!) {
  createCategory(newCategory: $newCategory) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory($updateCategory: CategoryUpdateInput!) {
  updateCategory(updateCategory: $updateCategory)
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const updateCategoryRanks = /* GraphQL */ `mutation UpdateCategoryRanks(
  $updateCategoryRanks: [CategoryRankUpdateInput!]!
) {
  updateCategoryRanks(updateCategoryRanks: $updateCategoryRanks)
}
` as GeneratedMutation<
  APITypes.UpdateCategoryRanksMutationVariables,
  APITypes.UpdateCategoryRanksMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory($id: ID!) {
  deleteCategory(id: $id)
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const createService = /* GraphQL */ `mutation CreateService($newService: ServiceCreateInput!) {
  createService(newService: $newService) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServiceMutationVariables,
  APITypes.CreateServiceMutation
>;
export const updateService = /* GraphQL */ `mutation UpdateService($updateService: ServiceUpdateInput!) {
  updateService(updateService: $updateService)
}
` as GeneratedMutation<
  APITypes.UpdateServiceMutationVariables,
  APITypes.UpdateServiceMutation
>;
export const updateServiceRanks = /* GraphQL */ `mutation UpdateServiceRanks($updateServiceRanks: [ServiceRankUpdateInput!]!) {
  updateServiceRanks(updateServiceRanks: $updateServiceRanks)
}
` as GeneratedMutation<
  APITypes.UpdateServiceRanksMutationVariables,
  APITypes.UpdateServiceRanksMutation
>;
export const deleteService = /* GraphQL */ `mutation DeleteService($id: ID!) {
  deleteService(id: $id)
}
` as GeneratedMutation<
  APITypes.DeleteServiceMutationVariables,
  APITypes.DeleteServiceMutation
>;
export const createDiscount = /* GraphQL */ `mutation CreateDiscount($newDiscount: DiscountCreateInput!) {
  createDiscount(newDiscount: $newDiscount) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDiscountMutationVariables,
  APITypes.CreateDiscountMutation
>;
export const updateDiscount = /* GraphQL */ `mutation UpdateDiscount($updateDiscount: DiscountUpdateInput!) {
  updateDiscount(updateDiscount: $updateDiscount)
}
` as GeneratedMutation<
  APITypes.UpdateDiscountMutationVariables,
  APITypes.UpdateDiscountMutation
>;
export const deleteDiscount = /* GraphQL */ `mutation DeleteDiscount($id: ID!) {
  deleteDiscount(id: $id)
}
` as GeneratedMutation<
  APITypes.DeleteDiscountMutationVariables,
  APITypes.DeleteDiscountMutation
>;
export const createPromo = /* GraphQL */ `mutation CreatePromo($newPromo: PromoCreateInput!) {
  createPromo(newPromo: $newPromo) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePromoMutationVariables,
  APITypes.CreatePromoMutation
>;
export const updatePromo = /* GraphQL */ `mutation UpdatePromo($updatePromo: PromoUpdateInput!) {
  updatePromo(updatePromo: $updatePromo)
}
` as GeneratedMutation<
  APITypes.UpdatePromoMutationVariables,
  APITypes.UpdatePromoMutation
>;
export const deletePromo = /* GraphQL */ `mutation DeletePromo($id: ID!) {
  deletePromo(id: $id)
}
` as GeneratedMutation<
  APITypes.DeletePromoMutationVariables,
  APITypes.DeletePromoMutation
>;
export const createEmployee = /* GraphQL */ `mutation CreateEmployee($newEmployee: EmployeeCreateInput!) {
  createEmployee(newEmployee: $newEmployee) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmployeeMutationVariables,
  APITypes.CreateEmployeeMutation
>;
export const updateEmployee = /* GraphQL */ `mutation UpdateEmployee($updateEmployee: EmployeeUpdateInput!) {
  updateEmployee(updateEmployee: $updateEmployee)
}
` as GeneratedMutation<
  APITypes.UpdateEmployeeMutationVariables,
  APITypes.UpdateEmployeeMutation
>;
export const createCustomer = /* GraphQL */ `mutation CreateCustomer($newCustomer: CustomerCreateInput!) {
  createCustomer(newCustomer: $newCustomer) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomerMutationVariables,
  APITypes.CreateCustomerMutation
>;
export const updateCustomer = /* GraphQL */ `mutation UpdateCustomer($updateCustomer: CustomerUpdateInput!) {
  updateCustomer(updateCustomer: $updateCustomer)
}
` as GeneratedMutation<
  APITypes.UpdateCustomerMutationVariables,
  APITypes.UpdateCustomerMutation
>;
export const deleteCustomer = /* GraphQL */ `mutation DeleteCustomer($id: ID!) {
  deleteCustomer(id: $id)
}
` as GeneratedMutation<
  APITypes.DeleteCustomerMutationVariables,
  APITypes.DeleteCustomerMutation
>;
export const addCustomerTag = /* GraphQL */ `mutation AddCustomerTag($customerId: ID!, $tag: String!) {
  addCustomerTag(customerId: $customerId, tag: $tag)
}
` as GeneratedMutation<
  APITypes.AddCustomerTagMutationVariables,
  APITypes.AddCustomerTagMutation
>;
export const deleteCustomerTag = /* GraphQL */ `mutation DeleteCustomerTag($customerId: ID!, $tag: String!) {
  deleteCustomerTag(customerId: $customerId, tag: $tag)
}
` as GeneratedMutation<
  APITypes.DeleteCustomerTagMutationVariables,
  APITypes.DeleteCustomerTagMutation
>;
export const setGoogleReviewDone = /* GraphQL */ `mutation SetGoogleReviewDone($customerId: ID!) {
  setGoogleReviewDone(customerId: $customerId)
}
` as GeneratedMutation<
  APITypes.SetGoogleReviewDoneMutationVariables,
  APITypes.SetGoogleReviewDoneMutation
>;
export const setGoogleReviewClicked = /* GraphQL */ `mutation SetGoogleReviewClicked($customerId: ID!) {
  setGoogleReviewClicked(customerId: $customerId)
}
` as GeneratedMutation<
  APITypes.SetGoogleReviewClickedMutationVariables,
  APITypes.SetGoogleReviewClickedMutation
>;
export const createOrder = /* GraphQL */ `mutation CreateOrder($newOrder: OrderCreateInput!) {
  createOrder(newOrder: $newOrder) {
    id
    merchantId
    number
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderMutationVariables,
  APITypes.CreateOrderMutation
>;
export const updateOrder = /* GraphQL */ `mutation UpdateOrder($updateOrder: OrderUpdateInput!) {
  updateOrder(updateOrder: $updateOrder)
}
` as GeneratedMutation<
  APITypes.UpdateOrderMutationVariables,
  APITypes.UpdateOrderMutation
>;
export const deleteOrder = /* GraphQL */ `mutation DeleteOrder($id: ID!) {
  deleteOrder(id: $id)
}
` as GeneratedMutation<
  APITypes.DeleteOrderMutationVariables,
  APITypes.DeleteOrderMutation
>;
export const addOrderCustomer = /* GraphQL */ `mutation AddOrderCustomer($newOrderCustomer: OrderCustomerAddInput!) {
  addOrderCustomer(newOrderCustomer: $newOrderCustomer) {
    PK
    SK
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddOrderCustomerMutationVariables,
  APITypes.AddOrderCustomerMutation
>;
export const addOrderTip = /* GraphQL */ `mutation AddOrderTip($newOrderTip: OrderTipAddInput!) {
  addOrderTip(newOrderTip: $newOrderTip) {
    PK
    SK
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddOrderTipMutationVariables,
  APITypes.AddOrderTipMutation
>;
export const updateOrderTip = /* GraphQL */ `mutation UpdateOrderTip($updateOrderTip: OrderTipUpdateInput!) {
  updateOrderTip(updateOrderTip: $updateOrderTip)
}
` as GeneratedMutation<
  APITypes.UpdateOrderTipMutationVariables,
  APITypes.UpdateOrderTipMutation
>;
export const deleteOrderTip = /* GraphQL */ `mutation DeleteOrderTip($orderId: ID!, $tipId: ID!) {
  deleteOrderTip(orderId: $orderId, tipId: $tipId)
}
` as GeneratedMutation<
  APITypes.DeleteOrderTipMutationVariables,
  APITypes.DeleteOrderTipMutation
>;
export const addOrderDiscount = /* GraphQL */ `mutation AddOrderDiscount($newOrderDiscount: OrderDiscountAddInput!) {
  addOrderDiscount(newOrderDiscount: $newOrderDiscount) {
    PK
    SK
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddOrderDiscountMutationVariables,
  APITypes.AddOrderDiscountMutation
>;
export const updateOrderDiscount = /* GraphQL */ `mutation UpdateOrderDiscount($updateOrderDiscount: OrderDiscountUpdateInput!) {
  updateOrderDiscount(updateOrderDiscount: $updateOrderDiscount)
}
` as GeneratedMutation<
  APITypes.UpdateOrderDiscountMutationVariables,
  APITypes.UpdateOrderDiscountMutation
>;
export const deleteOrderDiscount = /* GraphQL */ `mutation DeleteOrderDiscount($orderId: ID!, $discountId: ID!) {
  deleteOrderDiscount(orderId: $orderId, discountId: $discountId)
}
` as GeneratedMutation<
  APITypes.DeleteOrderDiscountMutationVariables,
  APITypes.DeleteOrderDiscountMutation
>;
export const addOrderPromo = /* GraphQL */ `mutation AddOrderPromo($newOrderPromo: OrderPromoAddInput!) {
  addOrderPromo(newOrderPromo: $newOrderPromo) {
    PK
    SK
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddOrderPromoMutationVariables,
  APITypes.AddOrderPromoMutation
>;
export const updateOrderPromo = /* GraphQL */ `mutation UpdateOrderPromo($updateOrderPromo: OrderPromoUpdateInput!) {
  updateOrderPromo(updateOrderPromo: $updateOrderPromo)
}
` as GeneratedMutation<
  APITypes.UpdateOrderPromoMutationVariables,
  APITypes.UpdateOrderPromoMutation
>;
export const deleteOrderPromo = /* GraphQL */ `mutation DeleteOrderPromo($orderId: ID!, $promoId: ID!) {
  deleteOrderPromo(orderId: $orderId, promoId: $promoId)
}
` as GeneratedMutation<
  APITypes.DeleteOrderPromoMutationVariables,
  APITypes.DeleteOrderPromoMutation
>;
export const addOrderService = /* GraphQL */ `mutation AddOrderService($newOrderService: OrderServiceAddInput!) {
  addOrderService(newOrderService: $newOrderService) {
    PK
    SK
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddOrderServiceMutationVariables,
  APITypes.AddOrderServiceMutation
>;
export const updateOrderService = /* GraphQL */ `mutation UpdateOrderService($updateOrderService: OrderServiceUpdateInput!) {
  updateOrderService(updateOrderService: $updateOrderService)
}
` as GeneratedMutation<
  APITypes.UpdateOrderServiceMutationVariables,
  APITypes.UpdateOrderServiceMutation
>;
export const deleteOrderService = /* GraphQL */ `mutation DeleteOrderService($orderId: ID!, $serviceId: ID!) {
  deleteOrderService(orderId: $orderId, serviceId: $serviceId)
}
` as GeneratedMutation<
  APITypes.DeleteOrderServiceMutationVariables,
  APITypes.DeleteOrderServiceMutation
>;
export const createErrorLog = /* GraphQL */ `mutation CreateErrorLog($newError: ErrorLogInput!) {
  createErrorLog(newError: $newError) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateErrorLogMutationVariables,
  APITypes.CreateErrorLogMutation
>;
export const notifyOrderChanged = /* GraphQL */ `mutation NotifyOrderChanged(
  $id: ID!
  $amount: Float!
  $status: OrderStatus!
  $tenderType: TenderType
  $number: String
  $appointment: AWSDateTime
  $cardInfo: String
  $merchant: OrderMerchantInput!
  $customer: OrderCustomerInput
  $event: DynamoDBEventInput
  $timestamp: AWSTimestamp
  $createdAt: AWSDateTime
  $createdBy: String
  $updatedAt: AWSDateTime
  $updatedBy: String
  $topic: NotificationType
) {
  notifyOrderChanged(
    id: $id
    amount: $amount
    status: $status
    tenderType: $tenderType
    number: $number
    appointment: $appointment
    cardInfo: $cardInfo
    merchant: $merchant
    customer: $customer
    event: $event
    timestamp: $timestamp
    createdAt: $createdAt
    createdBy: $createdBy
    updatedAt: $updatedAt
    updatedBy: $updatedBy
    topic: $topic
  ) {
    id
    orderId
    merchantId
    entityType
    topic
    message {
      id
      amount
      tenderType
      status
      merchant {
        id
        dba
        __typename
      }
      customer {
        id
        merchantId
        firstName
        lastName
        email
        phone
        publicName
        qrcode
        address {
          name
          street1
          street2
          city
          state
          postalCode
          country
          __typename
        }
        birthdate
        image
        status
        type
        rewardPoints
        visits
        note
        createdAt
        updatedAt
        __typename
      }
      note
      number
      appointment
      cardInfo
      reviewLinkCreationDate
      reviewSentDate
      reviewSentType
      reviewId
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    seen
    event {
      id
      name
      source
      arn
      __typename
    }
    timestamp
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.NotifyOrderChangedMutationVariables,
  APITypes.NotifyOrderChangedMutation
>;
export const notifyAppointmentChanged = /* GraphQL */ `mutation NotifyAppointmentChanged(
  $id: ID!
  $startTime: String
  $endTime: String
  $status: AppointmentStatus
  $merchant: AppointmentMerchantInput!
  $customer: AppointmentCustomerInput
  $event: DynamoDBEventInput
  $amount: Float
  $note: String
  $timestamp: AWSTimestamp
  $createdAt: AWSDateTime
  $createdBy: String
  $updatedAt: AWSDateTime
  $updatedBy: String
  $topic: NotificationType
) {
  notifyAppointmentChanged(
    id: $id
    startTime: $startTime
    endTime: $endTime
    status: $status
    merchant: $merchant
    customer: $customer
    event: $event
    amount: $amount
    note: $note
    timestamp: $timestamp
    createdAt: $createdAt
    createdBy: $createdBy
    updatedAt: $updatedAt
    updatedBy: $updatedBy
    topic: $topic
  ) {
    id
    appointmentId
    merchantId
    entityType
    topic
    message {
      id
      startTime
      endTime
      status
      merchant {
        id
        dba
        __typename
      }
      customer {
        id
        firstName
        lastName
        email
        phone
        birthdate
        __typename
      }
      amount
      note
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    seen
    event {
      id
      name
      source
      arn
      __typename
    }
    timestamp
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.NotifyAppointmentChangedMutationVariables,
  APITypes.NotifyAppointmentChangedMutation
>;
export const createCampaign = /* GraphQL */ `mutation CreateCampaign($newCampaign: CampaignCreateInput!) {
  createCampaign(newCampaign: $newCampaign) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCampaignMutationVariables,
  APITypes.CreateCampaignMutation
>;
export const updateCampaign = /* GraphQL */ `mutation UpdateCampaign($updateCampaign: CampaignUpdateInput!) {
  updateCampaign(updateCampaign: $updateCampaign)
}
` as GeneratedMutation<
  APITypes.UpdateCampaignMutationVariables,
  APITypes.UpdateCampaignMutation
>;
export const deleteCampaign = /* GraphQL */ `mutation DeleteCampaign($merchantId: ID!, $campaignId: ID!) {
  deleteCampaign(merchantId: $merchantId, campaignId: $campaignId)
}
` as GeneratedMutation<
  APITypes.DeleteCampaignMutationVariables,
  APITypes.DeleteCampaignMutation
>;
export const sendCampaignTestMessage = /* GraphQL */ `mutation SendCampaignTestMessage(
  $merchantId: ID!
  $channel: CampaignChannel!
  $recipient: String!
  $message: String!
) {
  sendCampaignTestMessage(
    merchantId: $merchantId
    channel: $channel
    recipient: $recipient
    message: $message
  )
}
` as GeneratedMutation<
  APITypes.SendCampaignTestMessageMutationVariables,
  APITypes.SendCampaignTestMessageMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview($newReview: ReviewCreateInput) {
  createReview(newReview: $newReview) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview($updateReview: ReviewUpdateInput) {
  updateReview(updateReview: $updateReview)
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview($merchantId: ID!, $reviewId: ID!) {
  deleteReview(merchantId: $merchantId, reviewId: $reviewId)
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const scheduleReviewSending = /* GraphQL */ `mutation ScheduleReviewSending($orderId: ID!, $skipLoyalterReview: Boolean) {
  scheduleReviewSending(
    orderId: $orderId
    skipLoyalterReview: $skipLoyalterReview
  )
}
` as GeneratedMutation<
  APITypes.ScheduleReviewSendingMutationVariables,
  APITypes.ScheduleReviewSendingMutation
>;
export const createReminder = /* GraphQL */ `mutation CreateReminder($newReminder: ReminderCreateInput) {
  createReminder(newReminder: $newReminder) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReminderMutationVariables,
  APITypes.CreateReminderMutation
>;
export const updateReminder = /* GraphQL */ `mutation UpdateReminder($updateReminder: ReminderUpdateInput) {
  updateReminder(updateReminder: $updateReminder)
}
` as GeneratedMutation<
  APITypes.UpdateReminderMutationVariables,
  APITypes.UpdateReminderMutation
>;
export const deleteReminder = /* GraphQL */ `mutation DeleteReminder($merchantId: ID!, $reminderId: ID!) {
  deleteReminder(merchantId: $merchantId, reminderId: $reminderId)
}
` as GeneratedMutation<
  APITypes.DeleteReminderMutationVariables,
  APITypes.DeleteReminderMutation
>;
export const createBirthday = /* GraphQL */ `mutation CreateBirthday($newBirthday: BirthdayCreateInput) {
  createBirthday(newBirthday: $newBirthday) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBirthdayMutationVariables,
  APITypes.CreateBirthdayMutation
>;
export const updateBirthday = /* GraphQL */ `mutation UpdateBirthday($updateBirthday: BirthdayUpdateInput) {
  updateBirthday(updateBirthday: $updateBirthday)
}
` as GeneratedMutation<
  APITypes.UpdateBirthdayMutationVariables,
  APITypes.UpdateBirthdayMutation
>;
export const deleteBirthday = /* GraphQL */ `mutation DeleteBirthday($merchantId: ID!, $birthdayId: ID!) {
  deleteBirthday(merchantId: $merchantId, birthdayId: $birthdayId)
}
` as GeneratedMutation<
  APITypes.DeleteBirthdayMutationVariables,
  APITypes.DeleteBirthdayMutation
>;
export const updateCognitoUserAttributes = /* GraphQL */ `mutation UpdateCognitoUserAttributes(
  $username: String!
  $attributes: [CognitoUserAttributeInput!]!
) {
  updateCognitoUserAttributes(username: $username, attributes: $attributes)
}
` as GeneratedMutation<
  APITypes.UpdateCognitoUserAttributesMutationVariables,
  APITypes.UpdateCognitoUserAttributesMutation
>;
export const createAppointment = /* GraphQL */ `mutation CreateAppointment($newAppointment: AppointmentCreateInput!) {
  createAppointment(newAppointment: $newAppointment) {
    id
    merchantId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAppointmentMutationVariables,
  APITypes.CreateAppointmentMutation
>;
export const updateAppointment = /* GraphQL */ `mutation UpdateAppointment($updateAppointment: AppointmentUpdateInput!) {
  updateAppointment(updateAppointment: $updateAppointment)
}
` as GeneratedMutation<
  APITypes.UpdateAppointmentMutationVariables,
  APITypes.UpdateAppointmentMutation
>;
export const addAppointmentService = /* GraphQL */ `mutation AddAppointmentService(
  $newAppointmentService: AppointmentServiceAddInput!
) {
  addAppointmentService(newAppointmentService: $newAppointmentService) {
    PK
    SK
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddAppointmentServiceMutationVariables,
  APITypes.AddAppointmentServiceMutation
>;
export const updateAppointmentService = /* GraphQL */ `mutation UpdateAppointmentService(
  $updateAppointmentService: AppointmentServiceUpdateInput!
) {
  updateAppointmentService(updateAppointmentService: $updateAppointmentService)
}
` as GeneratedMutation<
  APITypes.UpdateAppointmentServiceMutationVariables,
  APITypes.UpdateAppointmentServiceMutation
>;
export const deleteAppointmentService = /* GraphQL */ `mutation DeleteAppointmentService($apptId: ID!, $serviceId: ID!) {
  deleteAppointmentService(apptId: $apptId, serviceId: $serviceId)
}
` as GeneratedMutation<
  APITypes.DeleteAppointmentServiceMutationVariables,
  APITypes.DeleteAppointmentServiceMutation
>;
