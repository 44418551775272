/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getMerchant = /* GraphQL */ `query GetMerchant($id: ID!) {
  getMerchant(id: $id) {
    id
    entityType
    merchantId
    dba
    status
    logo
    qrcode
    businessAddress {
      name
      street1
      street2
      city
      state
      postalCode
      country
      __typename
    }
    businessPhone
    businessEmail
    businessHours {
      monday {
        open
        close
        __typename
      }
      tuesday {
        open
        close
        __typename
      }
      wednesday {
        open
        close
        __typename
      }
      thursday {
        open
        close
        __typename
      }
      friday {
        open
        close
        __typename
      }
      saturday {
        open
        close
        __typename
      }
      sunday {
        open
        close
        __typename
      }
      __typename
    }
    processorMid
    processorName
    ownerName
    ownerEmail
    ownerPhone
    ownerAddress {
      name
      street1
      street2
      city
      state
      postalCode
      country
      __typename
    }
    checkinAppSetting {
      allowUserSelectService
      allowGuestCheckin
      allowUserSelectAppointment
      allowUserSelectTechnician
      showCustomAdvertisement
      customAdvertisementContent
      __typename
    }
    sendReviewSmsEnabled
    sendReviewSmsMode
    sendReviewSmsDelay
    sendReviewTriggerStatus
    reviewWebappTemplate
    isAssociatedToGoogle
    googleAccount
    googleLocation
    googleReviewUrl
    badReviewAlertRecipients {
      employeeId
      smsEnabled
      emailEnabled
      __typename
    }
    timezone
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMerchantQueryVariables,
  APITypes.GetMerchantQuery
>;
export const getMerchantLambda = /* GraphQL */ `query GetMerchantLambda($id: ID!) {
  getMerchantLambda(id: $id) {
    id
    dba
    logo
    businessAddress {
      name
      street1
      street2
      city
      state
      postalCode
      country
      __typename
    }
    businessPhone
    businessEmail
    businessHours {
      monday {
        open
        close
        __typename
      }
      tuesday {
        open
        close
        __typename
      }
      wednesday {
        open
        close
        __typename
      }
      thursday {
        open
        close
        __typename
      }
      friday {
        open
        close
        __typename
      }
      saturday {
        open
        close
        __typename
      }
      sunday {
        open
        close
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMerchantLambdaQueryVariables,
  APITypes.GetMerchantLambdaQuery
>;
export const listMerchants = /* GraphQL */ `query ListMerchants(
  $limit: Int!
  $status: MerchantStatus!
  $nextToken: String
) {
  listMerchants(limit: $limit, status: $status, nextToken: $nextToken) {
    merchants {
      id
      entityType
      merchantId
      dba
      status
      logo
      qrcode
      businessAddress {
        name
        street1
        street2
        city
        state
        postalCode
        country
        __typename
      }
      businessPhone
      businessEmail
      businessHours {
        monday {
          open
          close
          __typename
        }
        tuesday {
          open
          close
          __typename
        }
        wednesday {
          open
          close
          __typename
        }
        thursday {
          open
          close
          __typename
        }
        friday {
          open
          close
          __typename
        }
        saturday {
          open
          close
          __typename
        }
        sunday {
          open
          close
          __typename
        }
        __typename
      }
      processorMid
      processorName
      ownerName
      ownerEmail
      ownerPhone
      ownerAddress {
        name
        street1
        street2
        city
        state
        postalCode
        country
        __typename
      }
      checkinAppSetting {
        allowUserSelectService
        allowGuestCheckin
        allowUserSelectAppointment
        allowUserSelectTechnician
        showCustomAdvertisement
        customAdvertisementContent
        __typename
      }
      sendReviewSmsEnabled
      sendReviewSmsMode
      sendReviewSmsDelay
      sendReviewTriggerStatus
      reviewWebappTemplate
      isAssociatedToGoogle
      googleAccount
      googleLocation
      googleReviewUrl
      badReviewAlertRecipients {
        employeeId
        smsEnabled
        emailEnabled
        __typename
      }
      timezone
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMerchantsQueryVariables,
  APITypes.ListMerchantsQuery
>;
export const getAccessToken = /* GraphQL */ `query GetAccessToken($merchantId: ID!) {
  getAccessToken(merchantId: $merchantId) {
    access_token
    expires_in
    scope
    token_type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccessTokenQueryVariables,
  APITypes.GetAccessTokenQuery
>;
export const getCategory = /* GraphQL */ `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    name
    merchantId
    rank
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryQueryVariables,
  APITypes.GetCategoryQuery
>;
export const listCategories = /* GraphQL */ `query ListCategories($merchantId: ID!, $limit: Int!, $nextToken: String) {
  listCategories(
    merchantId: $merchantId
    limit: $limit
    nextToken: $nextToken
  ) {
    categories {
      id
      name
      merchantId
      rank
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategoriesQueryVariables,
  APITypes.ListCategoriesQuery
>;
export const getService = /* GraphQL */ `query GetService($id: ID!) {
  getService(id: $id) {
    id
    name
    amount
    merchantId
    categoryId
    categoryName
    label
    rank
    isAmountUp
    duration
    description
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceQueryVariables,
  APITypes.GetServiceQuery
>;
export const listServices = /* GraphQL */ `query ListServices($merchantId: ID!, $limit: Int!, $nextToken: String) {
  listServices(merchantId: $merchantId, limit: $limit, nextToken: $nextToken) {
    services {
      id
      name
      amount
      merchantId
      categoryId
      categoryName
      label
      rank
      isAmountUp
      duration
      description
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServicesQueryVariables,
  APITypes.ListServicesQuery
>;
export const listServicesLambda = /* GraphQL */ `query ListServicesLambda($merchantId: ID!, $limit: Int!, $nextToken: String) {
  listServicesLambda(
    merchantId: $merchantId
    limit: $limit
    nextToken: $nextToken
  ) {
    services {
      id
      name
      amount
      merchantId
      categoryId
      categoryName
      label
      rank
      isAmountUp
      duration
      description
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServicesLambdaQueryVariables,
  APITypes.ListServicesLambdaQuery
>;
export const getDiscount = /* GraphQL */ `query GetDiscount($id: ID!) {
  getDiscount(id: $id) {
    id
    merchantId
    code
    offerUnit
    offerAmount
    description
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDiscountQueryVariables,
  APITypes.GetDiscountQuery
>;
export const listDiscounts = /* GraphQL */ `query ListDiscounts($merchantId: ID!, $limit: Int!, $nextToken: String) {
  listDiscounts(merchantId: $merchantId, limit: $limit, nextToken: $nextToken) {
    discounts {
      id
      merchantId
      code
      offerUnit
      offerAmount
      description
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDiscountsQueryVariables,
  APITypes.ListDiscountsQuery
>;
export const getPromo = /* GraphQL */ `query GetPromo($id: ID!) {
  getPromo(id: $id) {
    id
    merchantId
    code
    offerUnit
    offerAmount
    description
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPromoQueryVariables, APITypes.GetPromoQuery>;
export const listPromos = /* GraphQL */ `query ListPromos($merchantId: ID!, $limit: Int!, $nextToken: String) {
  listPromos(merchantId: $merchantId, limit: $limit, nextToken: $nextToken) {
    promos {
      id
      merchantId
      code
      offerUnit
      offerAmount
      description
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPromosQueryVariables,
  APITypes.ListPromosQuery
>;
export const getEmployee = /* GraphQL */ `query GetEmployee($id: ID!) {
  getEmployee(id: $id) {
    id
    merchantId
    firstName
    lastName
    email
    phone
    username
    publicName
    qrcode
    address {
      name
      street1
      street2
      city
      state
      postalCode
      country
      __typename
    }
    birthdate
    image
    status
    role
    ratingStars
    available
    color
    noCognitoUser
    pin
    workHours {
      monday {
        start
        end
        __typename
      }
      tuesday {
        start
        end
        __typename
      }
      wednesday {
        start
        end
        __typename
      }
      thursday {
        start
        end
        __typename
      }
      friday {
        start
        end
        __typename
      }
      saturday {
        start
        end
        __typename
      }
      sunday {
        start
        end
        __typename
      }
      __typename
    }
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmployeeQueryVariables,
  APITypes.GetEmployeeQuery
>;
export const listEmployees = /* GraphQL */ `query ListEmployees($merchantId: ID!, $limit: Int!, $nextToken: String) {
  listEmployees(merchantId: $merchantId, limit: $limit, nextToken: $nextToken) {
    employees {
      id
      merchantId
      firstName
      lastName
      email
      phone
      username
      publicName
      qrcode
      address {
        name
        street1
        street2
        city
        state
        postalCode
        country
        __typename
      }
      birthdate
      image
      status
      role
      ratingStars
      available
      color
      noCognitoUser
      pin
      workHours {
        monday {
          start
          end
          __typename
        }
        tuesday {
          start
          end
          __typename
        }
        wednesday {
          start
          end
          __typename
        }
        thursday {
          start
          end
          __typename
        }
        friday {
          start
          end
          __typename
        }
        saturday {
          start
          end
          __typename
        }
        sunday {
          start
          end
          __typename
        }
        __typename
      }
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmployeesQueryVariables,
  APITypes.ListEmployeesQuery
>;
export const listEmployeesLambda = /* GraphQL */ `query ListEmployeesLambda($merchantId: ID!, $limit: Int!, $nextToken: String) {
  listEmployeesLambda(
    merchantId: $merchantId
    limit: $limit
    nextToken: $nextToken
  ) {
    employees {
      id
      merchantId
      firstName
      publicName
      workHours {
        monday {
          start
          end
          __typename
        }
        tuesday {
          start
          end
          __typename
        }
        wednesday {
          start
          end
          __typename
        }
        thursday {
          start
          end
          __typename
        }
        friday {
          start
          end
          __typename
        }
        saturday {
          start
          end
          __typename
        }
        sunday {
          start
          end
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmployeesLambdaQueryVariables,
  APITypes.ListEmployeesLambdaQuery
>;
export const getCustomer = /* GraphQL */ `query GetCustomer($id: ID!) {
  getCustomer(id: $id) {
    id
    merchantId
    firstName
    lastName
    email
    phone
    username
    publicName
    qrcode
    address {
      name
      street1
      street2
      city
      state
      postalCode
      country
      __typename
    }
    birthdate
    image
    status
    type
    rewardPoints
    visits
    note
    tags
    receiveSms
    receiveEmail
    disableReviewSms
    lastReviewOpenedAt
    googleReviewButtonClickedAt
    googleReviewCreatedAt
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerQueryVariables,
  APITypes.GetCustomerQuery
>;
export const getCustomerByPhone = /* GraphQL */ `query GetCustomerByPhone($merchantId: ID!, $phone: AWSPhone!) {
  getCustomerByPhone(merchantId: $merchantId, phone: $phone) {
    id
    merchantId
    firstName
    lastName
    email
    phone
    username
    publicName
    qrcode
    address {
      name
      street1
      street2
      city
      state
      postalCode
      country
      __typename
    }
    birthdate
    image
    status
    type
    rewardPoints
    visits
    note
    tags
    receiveSms
    receiveEmail
    disableReviewSms
    lastReviewOpenedAt
    googleReviewButtonClickedAt
    googleReviewCreatedAt
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerByPhoneQueryVariables,
  APITypes.GetCustomerByPhoneQuery
>;
export const getCustomerByPhoneLambda = /* GraphQL */ `query GetCustomerByPhoneLambda($merchantId: ID!, $phone: AWSPhone!) {
  getCustomerByPhoneLambda(merchantId: $merchantId, phone: $phone) {
    id
    merchantId
    firstName
    lastName
    phone
    publicName
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerByPhoneLambdaQueryVariables,
  APITypes.GetCustomerByPhoneLambdaQuery
>;
export const listCustomers = /* GraphQL */ `query ListCustomers(
  $merchantId: ID!
  $customerType: CustomerType
  $limit: Int!
  $nextToken: String
) {
  listCustomers(
    merchantId: $merchantId
    customerType: $customerType
    limit: $limit
    nextToken: $nextToken
  ) {
    customers {
      id
      merchantId
      firstName
      lastName
      email
      phone
      username
      publicName
      qrcode
      address {
        name
        street1
        street2
        city
        state
        postalCode
        country
        __typename
      }
      birthdate
      image
      status
      type
      rewardPoints
      visits
      note
      tags
      receiveSms
      receiveEmail
      disableReviewSms
      lastReviewOpenedAt
      googleReviewButtonClickedAt
      googleReviewCreatedAt
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomersQueryVariables,
  APITypes.ListCustomersQuery
>;
export const searchCustomers = /* GraphQL */ `query SearchCustomers(
  $merchantId: ID!
  $query: String!
  $limit: Int!
  $filters: SearchCustomersFilters!
  $options: SearchCustomersQueryOptions
) {
  searchCustomers(
    merchantId: $merchantId
    query: $query
    limit: $limit
    filters: $filters
    options: $options
  ) {
    customers {
      id
      merchantId
      firstName
      lastName
      email
      phone
      username
      publicName
      qrcode
      address {
        name
        street1
        street2
        city
        state
        postalCode
        country
        __typename
      }
      birthdate
      image
      status
      type
      rewardPoints
      visits
      note
      tags
      receiveSms
      receiveEmail
      disableReviewSms
      lastReviewOpenedAt
      googleReviewButtonClickedAt
      googleReviewCreatedAt
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    total
    queryId
    startCursor
    nextCursor
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCustomersQueryVariables,
  APITypes.SearchCustomersQuery
>;
export const validateCustomer = /* GraphQL */ `query ValidateCustomer(
  $merchantId: ID!
  $fieldType: CustomerValidationType!
  $fieldValue: String!
) {
  validateCustomer(
    merchantId: $merchantId
    fieldType: $fieldType
    fieldValue: $fieldValue
  )
}
` as GeneratedQuery<
  APITypes.ValidateCustomerQueryVariables,
  APITypes.ValidateCustomerQuery
>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    amount
    tenderType
    status
    merchant {
      id
      dba
      __typename
    }
    customer {
      id
      merchantId
      firstName
      lastName
      email
      phone
      publicName
      qrcode
      address {
        name
        street1
        street2
        city
        state
        postalCode
        country
        __typename
      }
      birthdate
      image
      status
      type
      rewardPoints
      visits
      note
      createdAt
      updatedAt
      __typename
    }
    note
    number
    appointment
    cardInfo
    reviewLinkCreationDate
    reviewSentDate
    reviewSentType
    reviewId
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const getOrderDetail = /* GraphQL */ `query GetOrderDetail($id: ID!) {
  getOrderDetail(id: $id) {
    id
    amount
    tenderType
    status
    merchant {
      id
      dba
      __typename
    }
    customer {
      id
      merchantId
      firstName
      lastName
      email
      phone
      publicName
      qrcode
      address {
        name
        street1
        street2
        city
        state
        postalCode
        country
        __typename
      }
      birthdate
      image
      status
      type
      rewardPoints
      visits
      note
      createdAt
      updatedAt
      __typename
    }
    note
    number
    appointment
    cardInfo
    reviewLinkCreationDate
    reviewSentDate
    reviewSentType
    reviewId
    services {
      id
      name
      amount
      categoryId
      categoryName
      label
      rank
      tenderType
      employeeId
      employeeName
      __typename
    }
    promos {
      id
      code
      offerUnit
      offerAmount
      amount
      description
      __typename
    }
    discounts {
      id
      code
      offerUnit
      offerAmount
      amount
      description
      __typename
    }
    redeems {
      id
      points
      amount
      __typename
    }
    taxes {
      id
      code
      offerUnit
      offerAmount
      amount
      description
      __typename
    }
    tips {
      id
      code
      offerUnit
      offerAmount
      amount
      description
      tenderType
      employeeId
      employeeName
      __typename
    }
    transactions {
      id
      mid
      amount
      status
      paymentType
      processor
      __typename
    }
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderDetailQueryVariables,
  APITypes.GetOrderDetailQuery
>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $merchantId: ID!
  $startDate: String
  $endDate: String
  $statusFilter: OrderStatusFilter
  $limit: Int!
  $nextToken: String
) {
  listOrders(
    merchantId: $merchantId
    startDate: $startDate
    endDate: $endDate
    statusFilter: $statusFilter
    limit: $limit
    nextToken: $nextToken
  ) {
    orders {
      id
      amount
      tenderType
      status
      merchant {
        id
        dba
        __typename
      }
      customer {
        id
        merchantId
        firstName
        lastName
        email
        phone
        publicName
        qrcode
        address {
          name
          street1
          street2
          city
          state
          postalCode
          country
          __typename
        }
        birthdate
        image
        status
        type
        rewardPoints
        visits
        note
        createdAt
        updatedAt
        __typename
      }
      note
      number
      appointment
      cardInfo
      reviewLinkCreationDate
      reviewSentDate
      reviewSentType
      reviewId
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const listOrdersByCustomer = /* GraphQL */ `query ListOrdersByCustomer(
  $customerId: ID!
  $startDate: String
  $endDate: String
  $limit: Int!
  $nextToken: String
) {
  listOrdersByCustomer(
    customerId: $customerId
    startDate: $startDate
    endDate: $endDate
    limit: $limit
    nextToken: $nextToken
  ) {
    orders {
      id
      amount
      tenderType
      status
      merchant {
        id
        dba
        __typename
      }
      customer {
        id
        merchantId
        firstName
        lastName
        email
        phone
        publicName
        qrcode
        address {
          name
          street1
          street2
          city
          state
          postalCode
          country
          __typename
        }
        birthdate
        image
        status
        type
        rewardPoints
        visits
        note
        createdAt
        updatedAt
        __typename
      }
      note
      number
      appointment
      cardInfo
      reviewLinkCreationDate
      reviewSentDate
      reviewSentType
      reviewId
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersByCustomerQueryVariables,
  APITypes.ListOrdersByCustomerQuery
>;
export const searchOrders = /* GraphQL */ `query SearchOrders(
  $merchantId: ID!
  $query: String
  $page: Int!
  $limit: Int!
  $startDate: String
  $endDate: String
  $status: OrderStatusFilter
) {
  searchOrders(
    merchantId: $merchantId
    query: $query
    page: $page
    limit: $limit
    startDate: $startDate
    endDate: $endDate
    status: $status
  ) {
    orders {
      id
      amount
      tenderType
      status
      merchant {
        id
        dba
        __typename
      }
      customer {
        id
        merchantId
        firstName
        lastName
        email
        phone
        publicName
        qrcode
        address {
          name
          street1
          street2
          city
          state
          postalCode
          country
          __typename
        }
        birthdate
        image
        status
        type
        rewardPoints
        visits
        note
        createdAt
        updatedAt
        __typename
      }
      note
      number
      appointment
      cardInfo
      reviewLinkCreationDate
      reviewSentDate
      reviewSentType
      reviewId
      services {
        id
        name
        amount
        categoryId
        categoryName
        label
        rank
        tenderType
        employeeId
        employeeName
        __typename
      }
      promos {
        id
        code
        offerUnit
        offerAmount
        amount
        description
        __typename
      }
      discounts {
        id
        code
        offerUnit
        offerAmount
        amount
        description
        __typename
      }
      redeems {
        id
        points
        amount
        __typename
      }
      taxes {
        id
        code
        offerUnit
        offerAmount
        amount
        description
        __typename
      }
      tips {
        id
        code
        offerUnit
        offerAmount
        amount
        description
        tenderType
        employeeId
        employeeName
        __typename
      }
      transactions {
        id
        mid
        amount
        status
        paymentType
        processor
        __typename
      }
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchOrdersQueryVariables,
  APITypes.SearchOrdersQuery
>;
export const getStatistics = /* GraphQL */ `query GetStatistics(
  $merchantId: ID!
  $statisticType: StatisticType
  $startDate: String
  $endDate: String
  $limit: Int!
  $nextToken: String
) {
  getStatistics(
    merchantId: $merchantId
    statisticType: $statisticType
    startDate: $startDate
    endDate: $endDate
    limit: $limit
    nextToken: $nextToken
  ) {
    statistics {
      PK
      SK
      id
      startDate
      entityType
      merchantId
      cardAmount
      cardTipAmount
      cardTotal
      cashAmount
      cashTipAmount
      cashTotal
      allTotal
      orderCounts
      orderNumberCounter
      customerCounts
      employeeCounts
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatisticsQueryVariables,
  APITypes.GetStatisticsQuery
>;
export const getOrderStatistics = /* GraphQL */ `query GetOrderStatistics(
  $merchantId: ID!
  $groupDateType: groupDateType!
  $startDate: String!
  $endDate: String!
) {
  getOrderStatistics(
    merchantId: $merchantId
    groupDateType: $groupDateType
    startDate: $startDate
    endDate: $endDate
  ) {
    merchantId
    startDate
    endDate
    groupDateType
    results {
      PK
      SK
      id
      entityType
      dateType
      merchantId
      groupDate
      groupDateType
      cardOrderAmount
      cardTipAmount
      cardTotal
      cashOrderAmount
      cashTipAmount
      cashTotal
      cashCounts
      cardCounts
      tipCounts
      allCounts
      tipTotal
      allTotal
      orderCounts
      highestOrderAmount
      lowestOrderAmount
      averageOrderAmount
      highestTipAmount
      lowestTipAmount
      averageTipAmount
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderStatisticsQueryVariables,
  APITypes.GetOrderStatisticsQuery
>;
export const getEmployeeStatistics = /* GraphQL */ `query GetEmployeeStatistics(
  $merchantId: ID!
  $groupDateType: groupDateType!
  $startDate: String!
  $endDate: String!
) {
  getEmployeeStatistics(
    merchantId: $merchantId
    groupDateType: $groupDateType
    startDate: $startDate
    endDate: $endDate
  ) {
    merchantId
    startDate
    endDate
    groupDateType
    results {
      PK
      SK
      id
      entityType
      dateType
      merchantId
      employeeId
      employeeName
      groupDate
      groupDateType
      serviceAllCounts
      serviceCardCounts
      serviceCashCounts
      serviceCardAmount
      serviceCashAmount
      serviceTotal
      tipAllCounts
      tipCardCounts
      tipCashCounts
      tipCardAmount
      tipCashAmount
      tipTotal
      cardTotal
      cashTotal
      allTotal
      highestServiceAmount
      lowestServiceAmount
      averageServiceAmount
      highestTipAmount
      lowestTipAmount
      averageTipAmount
      summary {
        PK
        SK
        entityType
        name
        amount
        tenderType
        status
        customerId
        customerName
        createdAt
        __typename
      }
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmployeeStatisticsQueryVariables,
  APITypes.GetEmployeeStatisticsQuery
>;
export const getCustomerTypeCounts = /* GraphQL */ `query GetCustomerTypeCounts($merchantId: ID!) {
  getCustomerTypeCounts(merchantId: $merchantId) {
    merchantId
    results {
      customerType
      counts
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerTypeCountsQueryVariables,
  APITypes.GetCustomerTypeCountsQuery
>;
export const getReviewStatistics = /* GraphQL */ `query GetReviewStatistics($merchantId: ID!, $reviewType: ReviewType!) {
  getReviewStatistics(merchantId: $merchantId, reviewType: $reviewType) {
    merchantId
    reviewType
    results {
      merchantId
      reviewType
      averageRating
      reviewCounts
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReviewStatisticsQueryVariables,
  APITypes.GetReviewStatisticsQuery
>;
export const listCampaigns = /* GraphQL */ `query ListCampaigns($merchantId: ID!, $limit: Int!, $nextToken: String) {
  listCampaigns(merchantId: $merchantId, limit: $limit, nextToken: $nextToken) {
    campaigns {
      merchantId
      dba
      id
      name
      channel
      type
      status
      frequency
      startDate
      endDate
      promoId
      promoCode
      promoOfferUnit
      promoAmount
      promoDescription
      templateMessage
      rules {
        name
        entityType
        fieldPath
        fieldType
        valueType
        operation
        value
        __typename
      }
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCampaignsQueryVariables,
  APITypes.ListCampaignsQuery
>;
export const getCampaign = /* GraphQL */ `query GetCampaign($merchantId: ID!, $campaignId: ID!) {
  getCampaign(merchantId: $merchantId, campaignId: $campaignId) {
    merchantId
    dba
    id
    name
    channel
    type
    status
    frequency
    startDate
    endDate
    promoId
    promoCode
    promoOfferUnit
    promoAmount
    promoDescription
    templateMessage
    rules {
      name
      entityType
      fieldPath
      fieldType
      valueType
      operation
      value
      __typename
    }
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCampaignQueryVariables,
  APITypes.GetCampaignQuery
>;
export const getReview = /* GraphQL */ `query GetReview($merchantId: ID!, $reviewId: ID!) {
  getReview(merchantId: $merchantId, reviewId: $reviewId) {
    merchantId
    id
    type
    orderId
    customerId
    customerFirstName
    customerLastName
    customerEmail
    customerPhone
    message
    orderRating
    serviceRatings {
      id
      name
      amount
      categoryId
      categoryName
      label
      rank
      employeeId
      employeeName
      rating
      __typename
    }
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReviewQueryVariables, APITypes.GetReviewQuery>;
export const listReviews = /* GraphQL */ `query ListReviews($merchantId: ID!, $limit: Int!, $nextToken: String) {
  listReviews(merchantId: $merchantId, limit: $limit, nextToken: $nextToken) {
    reviews {
      merchantId
      id
      type
      orderId
      customerId
      customerFirstName
      customerLastName
      customerEmail
      customerPhone
      message
      orderRating
      serviceRatings {
        id
        name
        amount
        categoryId
        categoryName
        label
        rank
        employeeId
        employeeName
        rating
        __typename
      }
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsQueryVariables,
  APITypes.ListReviewsQuery
>;
export const getReminder = /* GraphQL */ `query GetReminder($merchantId: ID!, $reminderId: ID!) {
  getReminder(merchantId: $merchantId, reminderId: $reminderId) {
    id
    merchantId
    promotionId
    threshold
    totalDelivered
    customerTypes
    reminderStatus
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReminderQueryVariables,
  APITypes.GetReminderQuery
>;
export const listReminders = /* GraphQL */ `query ListReminders($merchantId: ID!, $limit: Int!, $nextToken: String) {
  listReminders(merchantId: $merchantId, limit: $limit, nextToken: $nextToken) {
    reminders {
      id
      merchantId
      promotionId
      threshold
      totalDelivered
      customerTypes
      reminderStatus
      message
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRemindersQueryVariables,
  APITypes.ListRemindersQuery
>;
export const getBirthday = /* GraphQL */ `query GetBirthday($merchantId: ID!, $birthdayId: ID!) {
  getBirthday(merchantId: $merchantId, birthdayId: $birthdayId) {
    id
    merchantId
    promotionId
    threshold
    totalDelivered
    customerTypes
    reminderStatus
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBirthdayQueryVariables,
  APITypes.GetBirthdayQuery
>;
export const listBirthdays = /* GraphQL */ `query ListBirthdays($merchantId: ID!, $limit: Int!, $nextToken: String) {
  listBirthdays(merchantId: $merchantId, limit: $limit, nextToken: $nextToken) {
    birthdays {
      id
      merchantId
      promotionId
      threshold
      totalDelivered
      customerTypes
      reminderStatus
      message
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBirthdaysQueryVariables,
  APITypes.ListBirthdaysQuery
>;
export const listCognitoUsers = /* GraphQL */ `query ListCognitoUsers($limit: Int!, $nextToken: String) {
  listCognitoUsers(limit: $limit, nextToken: $nextToken) {
    users {
      username
      attributes {
        name
        value
        __typename
      }
      createDate
      lastModifiedDate
      enabled
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCognitoUsersQueryVariables,
  APITypes.ListCognitoUsersQuery
>;
export const getCognitoUser = /* GraphQL */ `query GetCognitoUser($username: String!) {
  getCognitoUser(username: $username) {
    username
    attributes {
      name
      value
      __typename
    }
    createDate
    lastModifiedDate
    enabled
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCognitoUserQueryVariables,
  APITypes.GetCognitoUserQuery
>;
export const getAppointment = /* GraphQL */ `query GetAppointment($id: ID!) {
  getAppointment(id: $id) {
    id
    startTime
    endTime
    status
    merchant {
      id
      dba
      __typename
    }
    customer {
      id
      firstName
      lastName
      email
      phone
      birthdate
      __typename
    }
    amount
    note
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAppointmentQueryVariables,
  APITypes.GetAppointmentQuery
>;
export const getAppointmentDetail = /* GraphQL */ `query GetAppointmentDetail($id: ID!) {
  getAppointmentDetail(id: $id) {
    id
    startTime
    endTime
    status
    merchant {
      id
      dba
      __typename
    }
    customer {
      id
      firstName
      lastName
      email
      phone
      birthdate
      __typename
    }
    amount
    note
    services {
      id
      startTime
      endTime
      name
      amount
      categoryId
      categoryName
      employeeId
      employeeName
      __typename
    }
    timestamp
    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAppointmentDetailQueryVariables,
  APITypes.GetAppointmentDetailQuery
>;
export const listAppointments = /* GraphQL */ `query ListAppointments(
  $merchantId: ID!
  $startTime: String
  $endTime: String
  $employeeFilter: String
  $customerPhoneFilter: String
  $status: AppointmentStatus
) {
  listAppointments(
    merchantId: $merchantId
    startTime: $startTime
    endTime: $endTime
    employeeFilter: $employeeFilter
    customerPhoneFilter: $customerPhoneFilter
    status: $status
  ) {
    appointments {
      id
      startTime
      endTime
      status
      merchant {
        id
        dba
        __typename
      }
      customer {
        id
        firstName
        lastName
        email
        phone
        birthdate
        __typename
      }
      amount
      note
      services {
        id
        startTime
        endTime
        name
        amount
        categoryId
        categoryName
        employeeId
        employeeName
        __typename
      }
      timestamp
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAppointmentsQueryVariables,
  APITypes.ListAppointmentsQuery
>;
export const listAppointmentsLambda = /* GraphQL */ `query ListAppointmentsLambda(
  $merchantId: ID!
  $startTime: String
  $endTime: String
  $employeeFilter: String
  $customerPhoneFilter: String
) {
  listAppointmentsLambda(
    merchantId: $merchantId
    startTime: $startTime
    endTime: $endTime
    employeeFilter: $employeeFilter
    customerPhoneFilter: $customerPhoneFilter
  ) {
    appointments {
      id
      startTime
      endTime
      status
      merchant {
        id
        dba
        __typename
      }
      services {
        id
        startTime
        endTime
        name
        categoryId
        categoryName
        __typename
      }
      createdAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAppointmentsLambdaQueryVariables,
  APITypes.ListAppointmentsLambdaQuery
>;
export const getEmployeeIncomeReport = /* GraphQL */ `query GetEmployeeIncomeReport(
  $merchantId: ID!
  $employeeFilter: String
  $dateLabel: String
  $startDate: String!
  $endDate: String!
) {
  getEmployeeIncomeReport(
    merchantId: $merchantId
    employeeFilter: $employeeFilter
    dateLabel: $dateLabel
    startDate: $startDate
    endDate: $endDate
  ) {
    incomeReports {
      employeeId
      employeeName
      employeeColor
      merchantId
      startDate
      endDate
      dateLabel
      revenue
      commission
      tip
      Income
      cashIncome
      checkIncome
      detailByDay {
        orderId
        orderNumber
        dateTime
        serviceName
        serviceAmount
        tipAmount
        __typename
      }
      detailByDateRange {
        dateTime
        orderCounts
        totalServiceAmount
        totalTipAmount
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmployeeIncomeReportQueryVariables,
  APITypes.GetEmployeeIncomeReportQuery
>;
