import React, { createContext as reactCreateContext, useReducer, useContext, useRef, useEffect } from 'react';

import set from 'lodash/set';
import isNil from 'lodash/isNil';

export interface State {
    [value: string]: any
};

interface Handler<T extends State> {
    name: string,
    handler: (state: T, payload: any) => any
};

export interface Definition<T extends State> {
    [event: string]: Handler<T>
};

interface Event {
    [name: string]: (payload?: any) => { type: string, payload: any }
};

interface ReducerAction {
    type: string,
    payload: any
};

export type Action = <T extends State>(dispatch: React.Dispatch<ReducerAction>, state: T) => any;

interface ContextValue<T extends State> {
    state?: T,
    dispatch?: React.Dispatch<ReducerAction>
};

export const createContext = <T extends State>(definition: Definition<T>, initialState: T) => {
    const context = reactCreateContext<ContextValue<T>>({});
    const reducer = (state: T, action: ReducerAction): any => {
        const handler = definition[action.type];

        if (isNil(handler)) {
            throw new Error(`Unknown context handler ${action.type}`);
        }

        return handler.handler(state, action.payload);
    };
    const Provider = ({ children }: { children: React.ReactNode }) => {
        const [state, dispatch] = useReducer(reducer, initialState);
        return (
            <context.Provider value={{ state, dispatch }}>
                {children}
            </context.Provider>
        );
    };
    const useFunction = () => {
        const { state, dispatch: contextDispatch } = useContext(context);
        const isMounted = useRef(true);
        const stateRef = useRef(state);
        const dispatchRef = useRef(contextDispatch);

        useEffect(() => {
            isMounted.current = true;
            return () => {
                isMounted.current = false;
            }
        }, []);

        useEffect(() => {
            stateRef.current = state;
            dispatchRef.current = contextDispatch;
        }, [state, contextDispatch]);

        const dispatch = (action: Action) => {
            if (isMounted.current && !isNil(dispatchRef.current) && !isNil(stateRef.current)) {
                return action(dispatchRef.current, stateRef.current);
            }
        };
    
        return { state: state || ({} as T), dispatch };
    };
    const events: Event = Object.keys(definition)
        .reduce((acc, type) => {
            const name = definition[type]?.name || type;
            const action = (payload?: any) => ({ type, payload });
            set(acc, name, action);
            return acc;
        }, {});
    return { Provider, useFunction, events };
};