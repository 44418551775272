import React from 'react';

import isNil from 'lodash/isNil';

import { createContext, State, Definition, Action } from './common';

import API from '../api';

import type { Customer, Review, OrderDetail, Merchant, ServiceReviewRating } from '../api/types';

export interface AppState extends State {
    isLoading: boolean,
    error: Error | null,
    customer: Customer | null,
    orderDetail: OrderDetail | null,
    review: Review | null,
    merchant: Merchant | null,
    link: string | null
};

const initialState: AppState = {
    isLoading: false,
    error: null,
    customer: null,
    orderDetail: null,
    review: null,
    merchant: null,
    link: null
};

const definition: Definition<AppState> = {
    INITIALIZATION_START: {
        name: 'initializationStart',
        handler: () => ({
            isLoading: true,
            error: null,
            customer: null,
            orderDetail: null,
            review: null,
            merchant: null,
            link: null
        })
    },
    INITIALIZATION_SUCCESS: {
        name: 'initializationSuccess',
        handler: (state, { customer, orderDetail, review }) => ({
            ...state,
            isLoading: false,
            customer,
            orderDetail,
            review
        })
    },
    INITIALIZATION_FAILED: {
        name: 'initializationFailed',
        handler: (state, { error }) => ({
            ...state,
            isLoading: false,
            error
        })
    },
    CREATE_REVIEW_SUCCESS: {
        name: 'createReviewSuccess',
        handler: (state, { review }) => ({
            ...state,
            review
        })
    },
    CREATE_REVIEW_FAILED: {
        name: 'createReviewFailed',
        handler: (state, { error }) => ({
            ...state,
            error
        })
    },
    UPDATE_REVIEW_SUCCESS: {
        name: 'updateReviewSuccess',
        handler: (state, { review }) => ({
            ...state,
            review
        })
    },
    UPDATE_REVIEW_FAILED: {
        name: 'updateReviewFailed',
        handler: (state, { error }) => ({
            ...state,
            error
        })
    },
    LOAD_POST_REVIEW_DATA_START: {
        name: 'loadPostReviewDataStart',
        handler: (state) => ({
            ...state,
            isLoading: true,
            merchant: null,
            link: null
        })
    },
    LOAD_POST_REVIEW_DATA_SUCCESS: {
        name: 'loadPostReviewDataSuccess',
        handler: (state, { merchant, link }) => ({
            ...state,
            isLoading: false,
            merchant,
            link
        })
    },
    LOAD_POST_REVIEW_DATA_FAILED: {
        name: 'loadPostReviewDataFailed',
        handler: (state, { error }) => ({
            ...state,
            isLoading: false,
            error
        })
    },
    SET_GOOGLE_REVIEW_DONE_SUCCESS: {
        name: 'setGoogleReviewDoneSuccess',
        handler: (state, { customer }) => ({
            ...state,
            customer
        })
    },
    SET_GOOGLE_REVIEW_DONE_FAILED: {
        name: 'setGoogleReviewDoneFailed',
        handler: (state, { error }) => ({
            ...state,
            error
        })
    },
    SET_GOOGLE_REVIEW_CLICKED_START: {
        name: 'setGoogleReviewClickedStart',
        handler: (state) => ({
            ...state,
            isLoading: true
        })
    },
    SET_GOOGLE_REVIEW_CLICKED_SUCCESS: {
        name: 'setGoogleReviewClickedSuccess',
        handler: (state) => ({
            ...state,
            isLoading: false            
        })
    },
    SET_GOOGLE_REVIEW_CLICKED_FAIL: {
        name: 'setGoogleReviewClickedFail',
        handler: (state, { error }) => ({
            ...state,
            error
        })
    }
};

const { Provider, useFunction, events } = createContext<AppState>(definition, initialState);

export const ContextProvider = Provider;
export const useContext = useFunction;

export const actions = {
    initialize: (): Action => async (dispatch): Promise<boolean | undefined> => {
        dispatch(events.initializationStart());
        try {
            const response = await API.authenticate();

            if (isNil(response)) {
                throw new Error('Oops! The review time has ended.');
            }

            const customer = await API.getCustomer(response.customerId);

            if (isNil(customer)) {
                throw new Error('Failed to retrieve customer');
            }

            const orderDetail = await API.getOrderDetails(response.orderId);

            if (isNil(orderDetail)) {
                throw new Error('Failed to retrieve order');
            }

            API.setMerchantId(orderDetail.merchant.id);

            const review = await API.getReview(orderDetail.merchant.id, orderDetail.id);

            dispatch(events.initializationSuccess({ customer, orderDetail, review }));

            return response.skipLoyalterReview;
        } catch (error) {
            dispatch(events.initializationFailed({ error }));
        }

        return;
    },
    createReview: (serviceRatings: ServiceReviewRating[], orderRating: number, message: string): Action => async (dispatch, state) => {
        if (isNil(state.orderDetail) || isNil(state.customer)) {
            return;
        }

        try {
            const review: Review = {
                __typename: 'Review',
                id: state.orderDetail.id,
                merchantId: state.orderDetail.merchant.id,
                orderId: state.orderDetail.id,
                customerId: state.customer.id,
                customerEmail: state.customer.email,
                customerFirstName: state.customer.firstName,
                customerLastName: state.customer.lastName,
                customerPhone: state.customer.phone,
                serviceRatings,
                orderRating,
                message
            };

            await API.createReview(review);
            dispatch(events.createReviewSuccess({ review }));
        } catch (error) {
            dispatch(events.createReviewFailed({ error }));
        }
    },
    updateReview: (review: Review): Action => async (dispatch) => {
        try {
            await API.updateReview(review);
            dispatch(events.updateReviewSuccess({ review }));
        } catch (error) {
            dispatch(events.updateReviewFailed({ error }));
        }
    },
    loadPostReviewData: (): Action => async (dispatch, state) => {
        if (isNil(state.customer)) {
            return;
        }

        dispatch(events.loadPostReviewDataStart());
        try {
            const merchant = await API.loadMerchant(state.customer.merchantId);
            const link = await API.getGoogleReviewLink();
            dispatch(events.loadPostReviewDataSuccess({ merchant, link }));
        } catch (error) {
            dispatch(events.loadPostReviewDataFailed({ error }));
        }
    },
    setGoogleReviewDone: (): Action => async (dispatch, state) => {
        try {
            await API.setGoogleReviewDone(state.customer.id);
            dispatch(events.setGoogleReviewDoneSuccess({ customer: { ...state.customer, googleReviewCreatedAt: (new Date()).toISOString() } }));
        } catch (error) {
            dispatch(events.setGoogleReviewDoneFailed({ error }));
        }
    },
    setGoogleReviewClicked: (): Action => async (dispatch, state) => {
        dispatch(events.setGoogleReviewClickedStart());
        try {
            await API.setGoogleReviewClicked(state.customer.id);
            window.location.href = state.link;
        } catch (error) {
            dispatch(events.setGoogleReviewClickedFail({ error }));
        }

    }
};

export const withContextProvider = (Component: React.FunctionComponent) => () => {
    return (
        <ContextProvider>
            <Component />
        </ContextProvider>
    );
};
