import React, { useEffect } from 'react';
import { Button, Container, Spinner, Alert } from 'reactstrap';

import isNil from 'lodash/isNil';

import { actions, useContext } from '../../context';

const DEFAULT_MESSAGE = 'Please also leave us a review on Google that would be much appreciated to let others know about us. Thank You.';

const GoogleReview: React.FunctionComponent = () => {
    const { state, dispatch } = useContext();

    useEffect(() => {
        dispatch(actions.loadPostReviewData());
        // eslint-disable-next-line
    }, []);

    const setGoogleReviewDone = () => {
        dispatch(actions.setGoogleReviewDone());
    };

    const onGoogleReviewButtonClicked = async () => {
        await dispatch(actions.setGoogleReviewClicked());
    };

    if (state.isLoading) {
        return (
            <Container style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner>
                    Loading...
                </Spinner>
            </Container>
        );
    }

    return (
        <>
            <Container style={{ textAlign: 'center' }}>
            <Alert color='info'>
                <h4 className="alert-heading" style={{ color: 'primary' }}>
                    We Appreciate Your Valuable Feedback! <img src='/thankyou-love.png' style={{ width : '35px', marginRight: 10 }} alt='Thank You! Love'/>
                </h4>
                <h5 className="alert-heading">
                   Your review helps us grow. Please also review us on Google.
                </h5>
                { state.customer?.googleReviewCreatedAt && (
                    <>
                        <p style={{ fontSize: '1.1em', lineHeight: '1.1em', fontWeight: 400, marginTop: '30px' }}>
                            We won't ask you about Google Review in the future. Thanks for taking your precious time on the review.
                        </p>
                        <p style={{ fontSize: '1.1em', lineHeight: '1.1em', fontWeight: 400, marginTop: '30px' }}>
                            - {state.merchant?.dba}
                        </p>
                    </>
                )}

                { isNil(state.customer?.googleReviewCreatedAt) && (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            { !isNil(state.link) && (
                                <Button color='primary' onClick={onGoogleReviewButtonClicked} style={{ marginTop: '30px', fontSize: '1.2em'}}>
                                    <img src='/pointing-finger.png' style={{ width : '35px', marginRight: 10 }} alt='Review Google'/>
                                    Review Us on Google
                                </Button>
                            ) }
                        </div>
                        <p style={{ fontSize: '1.1em', lineHeight: '1.4em', fontWeight: 400, marginTop: '30px' }}>
                            {state.merchant?.reviewWebappTemplate || DEFAULT_MESSAGE}
                        </p>
                        
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button color="link" style={{ marginTop: '30px', color: 'grey', textDecoration: 'none' }} onClick={setGoogleReviewDone}>
                                Already done Google review?
                            </Button>
                        </div>
                        <p style={{ fontSize: '0.7em', lineHeight: '1em', fontWeight: 350, marginTop: '80px', color: 'gray' }}>
                            ** Any service offers for Google Review only able to use once. Show your Google review during check-in time to redeem the promotion.
                        </p>
                    </>
                )}
            </Alert>
        </Container>
            
        <Container style={{ position: 'absolute', bottom:0, marginTop: '60px', display: 'flex', justifyContent: 'center' }}>
            <p style={{ fontSize: '0.9em', lineHeight: '0.9em', fontWeight: 400 }}>Powered by Loyalter</p>
        </Container>
        </>  
    );
};

export default GoogleReview;