import { Amplify, API as AmplifyAPI } from 'aws-amplify';

import isNil from 'lodash/isNil';
import get from 'lodash/get';

import { Customer, Merchant, OrderDetail, Review, ReviewCreateInput, ReviewType, ReviewUpdateInput } from './types';
import * as Queries from './graphql/queries';
import * as Mutations from './graphql/mutations';


import { getConfiguration } from '../shared/utils';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

interface AuthenticationResponse {
    accessToken: string,
    idToken: string,
    tokenType: string,
    expiresIn: string,
    orderId: string,
    customerId: string,
    skipLoyalterReview?: boolean
};

export class API {

    private config: {
        AWS_REGION: string,
        USER_POOL_ID: string,
        CLIENT_ID: string,
        GRAPHQL_ENDPOINT: string,
        API_GATEWAY_NAME: string,
        API_GATEWAY_ENDPOINT: string  
    };

    private static instance: API | null = null;

    private accessToken: string | null = null;
    private merchantId: string | null = null;

    public static Create(): API {
        if (isNil(API.instance)) {
            API.instance = new API();
        }

        return API.instance;
    }

    private constructor() {
        this.config = getConfiguration();
    }

    public Initialize(): void {
        Amplify.configure({
            aws_project_region: this.config.AWS_REGION,
            aws_appsync_graphqlEndpoint: this.config.GRAPHQL_ENDPOINT,
            aws_appsync_region: this.config.AWS_REGION,
            aws_appsync_authenticationType: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,       
            Auth: {
                region: this.config.AWS_REGION,
                userPoolId: this.config.USER_POOL_ID,
                userPoolWebClientId: this.config.CLIENT_ID,
                mandatorySignIn: true
            },
            API: {
                endpoints: [
                    {
                        name: this.config.API_GATEWAY_NAME,
                        endpoint: this.config.API_GATEWAY_ENDPOINT
                    }
                ]
            }
        });
    }

    public async authenticate(): Promise<AuthenticationResponse | null> {
        try {
            const token = window.location.hash.split('#')[1];

            if (isNil(token)) {
                console.log('token is null', token);
                return null;
            }

            const response = await AmplifyAPI.post(this.config.API_GATEWAY_NAME, '/prod/review/authenticate', {
                body: { token }
            });

            this.accessToken = response.accessToken;

            return response;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    public async getCustomer(id: string): Promise<Customer | null> {
        const response = await AmplifyAPI.graphql({
            query: Queries.getCustomer, variables: { id },
            authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
            authToken: `Loyalter ${this.accessToken}`
        });
        return get(response, 'data.getCustomer') ?? null;
    }

    public async getOrderDetails(id: string): Promise<OrderDetail | null> {
        const response = await AmplifyAPI.graphql({
            query: Queries.getOrderDetail, variables: { id },
            authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
            authToken: `Loyalter ${this.accessToken}`
        });
        return get(response, 'data.getOrderDetail') ?? null;
    }

    public async getReview(merchantId: string, reviewId: string): Promise<Review | null> {
        const response = await AmplifyAPI.graphql({
            query: Queries.getReview, variables: { merchantId, reviewId },
            authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
            authToken: `Loyalter ${this.accessToken}`
        });
        return get(response, 'data.getReview') ?? null;
    }

    public async createReview(review: Review): Promise<string> {
        const newReview: ReviewCreateInput = {
            id: review.id,
            merchantId: review.merchantId,
            type: ReviewType.LOYALTER,
            orderId: review.orderId,
            customerId: review.customerId,
            customerFirstName: review.customerFirstName,
            customerLastName: review.customerLastName,
            customerEmail: review.customerEmail,
            customerPhone: review.customerPhone,
            serviceRatings: (review.serviceRatings || []).map(r => isNil(r) ? null : ({
                id: r.id,
                name: r.name,
                amount: r.amount,
                categoryId: r.categoryId,
                categoryName: r.categoryId,
                label: r.label,
                rank: r.rank,
                employeeId: r.employeeId,
                employeeName: r.employeeName,
                rating: r.rating
            })),
            orderRating: review.orderRating,
            message: review.message
        };

        const response = await AmplifyAPI.graphql({
            query: Mutations.createReview, variables: { newReview },
            authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
            authToken: `Loyalter ${this.accessToken}`
        });
        return get(response, 'data.createReview.id') || '';
    }

    public async updateReview(review: Review): Promise<boolean> {
        const updateReview: ReviewUpdateInput = {
            id: review.id,
            merchantId: review.merchantId,
            type: ReviewType.LOYALTER,
            orderId: review.orderId,
            customerId: review.customerId,
            customerFirstName: review.customerFirstName,
            customerLastName: review.customerLastName,
            customerEmail: review.customerEmail,
            customerPhone: review.customerPhone,
            serviceRatings: (review.serviceRatings || []).map(r => isNil(r) ? null : ({
                id: r.id,
                name: r.name,
                amount: r.amount,
                categoryId: r.categoryId,
                categoryName: r.categoryId,
                label: r.label,
                rank: r.rank,
                employeeId: r.employeeId,
                employeeName: r.employeeName,
                rating: r.rating
            })),
            orderRating: review.orderRating,
            message: review.message
        };

        await AmplifyAPI.graphql({
            query: Mutations.updateReview, variables: { updateReview },
            authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
            authToken: `Loyalter ${this.accessToken}`
        });
        return true;
    }
    
    public async getGoogleReviewLink(): Promise<string | null> {
        try {
            const merchantResponse = await AmplifyAPI.graphql({
                query: Queries.getMerchant, variables: { id: this.merchantId },
                authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
                authToken: `Loyalter ${this.accessToken}`
            });
            const merchant = get(merchantResponse, 'data.getMerchant');
    
            return get(merchant, 'googleReviewUrl') ?? null;
        } catch (error) {
            console.log(error);
            return null
        }
    }

    public setMerchantId(merchantId: string) {
        this.merchantId = merchantId;
    }

    public async loadMerchant(id: string): Promise<Merchant | null> {
        const response = await AmplifyAPI.graphql({
            query: Queries.getMerchant, variables: { id },
            authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
            authToken: `Loyalter ${this.accessToken}`
        });
        return get(response, 'data.getMerchant') ?? null;
    }

    public async setGoogleReviewDone(customerId: string): Promise<void> {
        await AmplifyAPI.graphql({
            query: Mutations.setGoogleReviewDone, variables: { customerId },
            authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
            authToken: `Loyalter ${this.accessToken}`
        })
    }

    public async setGoogleReviewClicked(customerId: string): Promise<void> {
        await AmplifyAPI.graphql({
            query: Mutations.setGoogleReviewClicked, variables: { customerId },
            authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
            authToken: `Loyalter ${this.accessToken}`
        })
    }
};

export default API.Create();