import { useRef } from 'react';

const useSafeAsyncCallback = <T extends unknown>(callback: (params?: T) => Promise<void>): (params?: T) => Promise<void> => {
    const isPending = useRef(false);

    return async (params?: T) => {
        if (isPending.current) {
            return;
        }

        isPending.current = true;
        await callback(params);
        isPending.current = false;
    };
};

export default useSafeAsyncCallback;