import ProductionConfig from '../configuration/production.json';
import StableConfig from '../configuration/stable.json';
import DevConfig from '../configuration/dev.json';

export const getConfiguration = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT;

    switch (environment) {
        case 'production':
            return ProductionConfig;
        case 'stable':
            return StableConfig;
        default:
            return DevConfig;
    }
};
