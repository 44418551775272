import React, { useEffect, useState } from 'react';
import { Container, Alert, Button, Input, Spinner } from 'reactstrap';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { actions, useContext } from '../../context';

import getTemplateMessage from '../../shared/template-messages';

const Feedback: React.FunctionComponent = () => {
    const { state, dispatch } = useContext();

    const [message, setMessage] = useState<string>(state.review?.message || '');
    const [templateMessage, setTemplateMessage] = useState<React.ReactNode | null>(null);
    const [isSending, setIsSending] = useState(false);
    const [hasSent, setHasSent] = useState(false);

    const onSubmit = async () => {
        if (!isEmpty(message) && !isNil(state.review)) {
            setIsSending(true);
            await dispatch(actions.updateReview({
                ...state.review,
                message
            }));
            setIsSending(false);
            setHasSent(true);
        }
    };

    useEffect(() => {
        dispatch(actions.loadPostReviewData());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isNil(state.review) && !isNil(state.merchant)) {
            setTemplateMessage(getTemplateMessage(state.review.orderRating || 0, state.merchant));
        }
        // eslint-disable-next-line
    }, [state.merchant]);

    if (state.isLoading || isNil(templateMessage)) {
        return (
            <Container style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner>
                    Loading...
                </Spinner>
            </Container>
        );
    }

    const thankyouTemplate = (
        <>
            <Container style={{ textAlign: 'center', marginTop: '30px' }}>
                <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 600, marginTop: '30px' }} >
                    Thank you for your time and feedback. Have a wonderful day!
                </p>
                <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }} >
                    - {state.orderDetail?.merchant.dba} Team
                </p>
            </Container>
            <Container style={{ position: 'absolute', bottom:0, marginTop: '60px', display: 'flex', justifyContent: 'center' }}>
                <p style={{ fontSize: '0.9em', lineHeight: '0.9em', fontWeight: 400 }}>Powered by Loyalter</p>
            </Container>
        </>
    )

    const feedbackTemplate = (
        <>
            <Container style={{ textAlign: 'center', marginTop: '30px' }}>
                <Alert color='info'>
                    {templateMessage}
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }} >
                        - {state.orderDetail?.merchant.dba} Team
                    </p>
                </Alert>
            </Container>
            <Container style={{ marginTop: '20px' }}>
                <Input
                    placeholder='Thought/Comment'
                    name='text'
                    type='textarea'
                    style={{ height: 120 }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    maxLength={250}
                />
            </Container>
            <Container style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                { isSending ? (
                    <Spinner>
                        Loading...
                    </Spinner>
                ) : (
                    <Button color='primary' style={{ width: 200 }} onClick={onSubmit}>
                        Done
                    </Button>
                ) }
            </Container> 
            <Container style={{ position: 'absolute', bottom:0, marginTop: '60px', display: 'flex', justifyContent: 'center' }}>
                <p style={{ fontSize: '0.9em', lineHeight: '0.9em', fontWeight: 400 }}>Powered by Loyalter</p>
            </Container>
        </>
    )

    return (
        <>
            { hasSent ? thankyouTemplate : feedbackTemplate}
        </>
    );
};

export default Feedback;