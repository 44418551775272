import React from 'react';

import isNil from 'lodash/isNil';

import type { Merchant } from '../api/types';

type TemplateNode = (merchant: Merchant) => React.ReactNode;

const formatPhoneNumber = (phone: string | null | undefined) => {
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/); 
    if (match) {
        //const intlCode = (match[1] ? '+1 ' : '');
        return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}

const TEMPLATE_MESSAGES: TemplateNode[][] = [
    [
        (merchant: Merchant): React.ReactNode => {
            return (
                <>
                    <h4 className='alert-heading'>
                        Thanks for sharing your feedback.
                    </h4>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }}>
                        We’re sorry your experience didn’t match your expectations.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        Please feel free to reach out to us {formatPhoneNumber(merchant.businessPhone)} with any further comments, concerns, or suggestions you wish to share.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        We would love to make things right if you give us another chance.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        Have a great day!
                    </p>
                </>
            );
        },
        (merchant: Merchant): React.ReactNode => {
            return (
                <>
                    <h4 className='alert-heading'>
                        We would like the opportunity to investigate your feedback further.
                    </h4>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }}>
                        Please could you call our team at {formatPhoneNumber(merchant.businessPhone)}?
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        We’ll work with you to resolve any issues as quickly as possible.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        Have a great day!
                    </p>
                </>
            );
        }
    ],
    [
        (merchant: Merchant): React.ReactNode => {
            return (
                <>
                    <h4 className='alert-heading'>
                        Thank you for your review.
                    </h4>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }}>
                        We’re sorry to hear you had a frustrating experience, but we really appreciate you bringing this issue to our attention.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        We will do our best to serve you next time.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        Have a great day!
                    </p>
                </>
            );
        },
        (merchant: Merchant): React.ReactNode => {
            return (
                <>
                    <h4 className='alert-heading'>
                        We apologize that our service did not satisfy your expectations.
                    </h4>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }}>
                        Please feel free to reach out to us {formatPhoneNumber(merchant.businessPhone)} with any further comments, concerns, or suggestions you wish to share.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        We would love to make things right if you give us another chance.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        Have a great day!
                    </p>
                </>
            );
        },
        (merchant: Merchant): React.ReactNode => {
            return (
                <>
                    <h4 className='alert-heading'>
                        We’re so sorry that your experience did not match your expectations.
                    </h4>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }}>
                        We would love to make things right if you give us another chance.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        Have a great day!
                    </p>
                </>
            );
        },
        (merchant: Merchant): React.ReactNode => {
            return (
                <>
                    <h4 className='alert-heading'>
                        We set a high standard for ourselves, and we’re so sorry to hear this was not met in your interaction with our business.
                    </h4>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }}>
                        Please let us know how we could improve our services better next time.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        Have a wonderful day!
                    </p>
                </>
            );
        },
        (merchant: Merchant): React.ReactNode => {
            return (
                <>
                    <h4 className='alert-heading'>
                        Thank you for bringing this to our attention.
                    </h4>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }}>
                        We’re sorry you had a bad experience. We’ll strive to do better.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        Please feel free to give us a call {formatPhoneNumber(merchant.businessPhone)} or leave us your thoughts in the comment we will address and correct the situation for you.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        Thanks again for your time today!
                    </p>
                </>
            );
        }
    ],
    [
        (merchant: Merchant): React.ReactNode => {
            return (
                <>
                    <h4 className='alert-heading'>
                        We apologize that our service did not satisfy your expectations.
                    </h4>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }}>
                        Is there something we could do better to earn an outstanding service from you?
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        Thanks for your time and feedback.
                    </p>
                </>
            );
        },
        (merchant: Merchant): React.ReactNode => {
            return (
                <>
                    <h4 className='alert-heading'>
                        We’re so sorry that your experience did not match your expectations.
                    </h4>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }}>
                        Please let us know if there is something that we could do to improve our service for your next visit.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        You have a nice day!
                    </p>
                </>
            );
        },
        (merchant: Merchant): React.ReactNode => {
            return (
                <>
                    <h4 className='alert-heading'>
                        We set a high standard for ourselves, and we’re so sorry to hear this was not met in your interaction with our business.
                    </h4>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }}>
                        Any suggestions would be much appreciated.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400 }}>
                        You have a wonderful day!
                    </p>
                </>
            );
        }
    ],
    [
        (merchant: Merchant): React.ReactNode => {
            return (
                <>
                    <h4 className='alert-heading'>
                        Thanks for your feedback.
                    </h4>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }}>
                        We always aim to deliver a great and pleasant experience to our customers.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '10px' }}>
                        Any thoughts or comments for an outstanding service would be much appropriated from us.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '10px' }}>
                        You have a great day and thanks again!
                    </p>
                </>
            );
        }
    ],
    [
        (merchant: Merchant): React.ReactNode => {
            return (
                <>
                    <h4 className='alert-heading'>
                        Thanks for your feedback.
                    </h4>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '30px' }}>
                        We always aim to deliver a great and pleasant experience to our customers.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '10px' }}>
                        Any thoughts or comments for an outstanding service would be much appropriated from us.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.2em', fontWeight: 400, marginTop: '10px' }}>
                        You have a great day and thanks again!
                    </p>
                </>
            );
        }
    ]
];


const getRandomIndex = (length: number) =>
    length > 1 ? Math.round(Math.random() * (length - 1)) : 0;

const getTemplateMessage = (rating: number, merchant: Merchant | null): React.ReactNode => {
    console.log('getTemplateMessage', rating, merchant)
    if (isNil(merchant)) {
        return null;
    }

    const arrayIndex = Math.floor(rating) - 1;

    if (arrayIndex < 0) {
        return null;
    }

    const array = TEMPLATE_MESSAGES[arrayIndex];

    if (isNil(array) || array.length === 0) {
        return null;
    }

    const templateIndex = getRandomIndex(array.length);

    return array[templateIndex](merchant);
};

export default getTemplateMessage;