import React from 'react';
import ReactDOM from 'react-dom/client';

import isNil from 'lodash/isNil';

import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import API from './api';

const rootElement = document.getElementById('root');

if (!isNil(rootElement)) {
    API.Initialize();
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}
