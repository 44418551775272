import React, { useEffect, useState } from 'react';
import { Button, Container, Input, Spinner } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import { IoStarOutline, IoStarSharp } from 'react-icons/io5';

import isNil from 'lodash/isNil';
import get from 'lodash/get';

import { actions, useContext } from '../../context';

import useSafeAsyncCallback from '../../shared/safe-async-callback';
import { OrderService, ServiceReviewRating } from '../../api/types';

const ACCEPT_SERVICE_RATING_LEVEL = 5;

interface StarPropTypes {
    filled: boolean,
    onMouseEnter: () => void,
    onMouseLeave: () => void,
    onClick: () => void
};

interface StarRatingPropTypes {
    value: number,
    onChange: (value: number) => void
};

const ratingToText = (rating: number): string => {
    switch (rating) {
        case 1:
            return 'Poor';
        case 2:
            return 'Fair';
        case 3:
            return 'Average';
        case 4:
            return 'Good';
        case 5:
            return 'Excellent';
        default:
            return ''
    }
};

const Star: React.FunctionComponent<StarPropTypes> = ({ filled, onMouseEnter, onMouseLeave, onClick }) => {
    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
            { filled ? (
                <IoStarSharp size={40} color='#FFD700' />
            ) : (
                <IoStarOutline size={40} color='rgba(0, 0, 0, 0.12)' />
            )}
        </div>
    )
};

const StarRating: React.FunctionComponent<StarRatingPropTypes> = ({ value, onChange }) => {
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const components = [];

    for (let i = 0; i < 5; i++) {
        components.push(
            <Star
                filled={i < currentValue}
                onMouseEnter={() => setCurrentValue(i + 1)}
                onMouseLeave={() => setCurrentValue(value)}
                onClick={() => onChange(i + 1)}
                key={i}
                />);
    }

    return (
        <div style={{ display: 'flex'}}>
            { components }
        </div>
    )
};

const Form: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const { state, dispatch } = useContext();

    const [orderRating, setOrderRating] = useState(0);
    const [averageOrderRating, setAverageOrderRating] = useState(0);
    const [serviceRatingsValue, setServiceRatingsValue] = useState<number[]>([]);
    const [message, setMessage] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isLoading = get(state, 'isLoading', false);

    const doLoad = useSafeAsyncCallback(async () => {
        const skipLoyalterReview = await dispatch(actions.initialize());

        if (skipLoyalterReview) {
            navigate('/google-link');
        }
    });

    useEffect(() => {
        doLoad();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setOrderRating(state.review?.orderRating || 0);
        setServiceRatingsValue(state.orderDetail?.services?.map((service) => {
            if (isNil(service) || isNil(state.review)) {
                return 0;
            }

            const serviceRating = (state.review.serviceRatings || []).find(r => r?.id === service.id);

            return isNil(serviceRating) ? 0 : serviceRating.rating;
        }) || []);
        setMessage(state.review?.message || '');
        // eslint-disable-next-line
    }, [state.review]);

    const onServiceRatingChange = (pos: number, value: number) => {
        const newServiceRatingsValue = [
            ...serviceRatingsValue.slice(0, pos),
            value,
            ...serviceRatingsValue.slice(pos + 1)
        ];
        const filteredServiceRatingsValue = newServiceRatingsValue.filter(v => v !== 0)

        if (orderRating === 0 && filteredServiceRatingsValue.length > 0) {
            const averageRating = Math.ceil(
                filteredServiceRatingsValue.reduce((acc, v) => acc + v, 0) / filteredServiceRatingsValue.length
            );

            setAverageOrderRating(averageRating);
        }

        setServiceRatingsValue(newServiceRatingsValue);
    };

    const onOverallRatingChange = (value: number) => {
        setOrderRating(value);
    };

    const onCommentChange = (value: string) => {
        setMessage(value);
    };

    const onReviewDone = async () => {
        if (isNil(state.orderDetail)) {
            return;
        }

        const effectiveOrderRating = orderRating === 0 ? averageOrderRating : orderRating;
        const services = (state.orderDetail.services || [])
            .filter(service => !isNil(service)) as OrderService[];
        const serviceRatings = services.map((service, i) => ({
            __typename: 'ServiceReviewRating',
            id: service.id,
            name: service.name,
            amount: service.amount,
            categoryId: service.categoryId,
            categoryName: service.categoryId,
            label: service.label,
            rank: service.rank,
            employeeId: service.employeeId,
            employeeName: service.employeeName,
            rating: serviceRatingsValue[i],
        })) as ServiceReviewRating[];

        setIsSubmitting(true);

        if (isNil(state.review)) {
            await dispatch(actions.createReview(serviceRatings, effectiveOrderRating, message))
        } else {
            await dispatch(actions.updateReview({
                ...state.review,
                serviceRatings,
                orderRating: effectiveOrderRating,
                message
            }));
        }

        setIsSubmitting(false);

        //filter out bad services including unrated ones
        const badServiceRatings = serviceRatings.filter(s => s.rating < ACCEPT_SERVICE_RATING_LEVEL).filter( s => s.rating !== 0);
        if (effectiveOrderRating >= ACCEPT_SERVICE_RATING_LEVEL && badServiceRatings.length === 0) {
            navigate('/google-review');
        } else {
            navigate('/feedback');
        }
    };

    if (isLoading) {
        return (
            <Container style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner>
                    Loading...
                </Spinner>
            </Container>
        );
    }

    if (!isNil(state.error) || isNil(state.orderDetail)) {
        return (
            <Container style={{ display: 'flex', justifyContent: 'center' }}>
                <h1 style={{ fontSize: '2em', lineHeight: '2em', fontWeight: 400 }}>
                    { isNil(state.error) ? 'Failed to load order details' : state.error.message }
                </h1>
            </Container>
        );
    }

    const isButtonDisabled = (orderRating === 0 && serviceRatingsValue.reduce((acc, v) => acc + v, 0) === 0) || isSubmitting;

    return (
        <>
            <Container style={{ textAlign: 'center', marginBottom: '50px' }}>
                <h1 style={{ fontSize: '1.7em', lineHeight: '1.5em', fontWeight: 450 }}>{state.orderDetail.merchant.dba}</h1>
                <p style={{ fontSize: '1em', lineHeight: '1em', fontWeight: 350 }}>We love to hear your feedback</p>
                <p style={{ fontSize: '1em', lineHeight: '1em', fontWeight: 350 }}>Please let us know how was your service today</p>
            </Container>
            { !isNil(state.orderDetail.services) && state.orderDetail.services.length > 0 && (
                <>
                    <hr style={{marginTop: 10}} />
                    { state.orderDetail.services.map((service, pos) => !isNil(service) && (
                        <Container style={{ marginTop: '10px' }} key={service.id}>
                            <p style={{ fontSize: '1.2em', lineHeight: '0.9em', fontWeight: 600 }}>{service.name}</p>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <StarRating value={serviceRatingsValue[pos]} onChange={(value) => onServiceRatingChange(pos, value)} />
                                <p style={{ fontSize: '1.2em', fontWeight: 400, marginBottom: 0 }}>{ratingToText(serviceRatingsValue[pos])}</p>
                            </div>
                        </Container>
                    ))}
                </>
            ) }
            <hr style={{marginTop: 10}} />
            <Container style={{ marginTop: '20px' }}>
                <p style={{ fontSize: '1.2em', lineHeight: '0.9em', fontWeight: 600 }}>Overall</p>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <StarRating value={orderRating || averageOrderRating} onChange={onOverallRatingChange} />
                    <p style={{ fontSize: '1.2em', fontWeight: 400, marginBottom: 0 }}>{ratingToText(orderRating)}</p>
                </div>
            </Container>
            <hr style={{marginTop: 10}} />
            <Container style={{ marginTop: '20px' }}>
                {/* <p style={{ fontSize: '1.2em', lineHeight: '0.9em', fontWeight: 500 }}>We would love to hear from you </p> */}
                <Input
                    placeholder='Thought/Comment'
                    name='text'
                    type='textarea'
                    style={{ height: 120 }}
                    value={message}
                    onChange={(e) => onCommentChange(e.target.value)}
                    maxLength={250}
                />
            </Container>
            <Container style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                <Button color='primary' style={{ width: 200 }} onClick={onReviewDone} disabled={isButtonDisabled}>
                    { isSubmitting ? (
                        <Spinner size="sm" />
                    ) : (
                        <span>
                            Submit
                        </span>
                    ) }
                </Button>
            </Container>
            <Container style={{ bottom:0, marginTop: '60px', display: 'flex', justifyContent: 'center' }}>
                <p style={{ fontSize: '0.9em', lineHeight: '0.9em', fontWeight: 400 }}>Powered by Loyalter</p>
            </Container>
        </>
    );
};

export default Form;