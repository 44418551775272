import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from './layout';
import Form from './views/form';
import Feedback from './views/feedback';
import GoogleReview from './views/google-review';
import GoogleLink from './views/google-link';

import { withContextProvider } from './context';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route index element={<Form />} />
                    <Route path='feedback' element={<Feedback />} />
                    <Route path='google-review' element={<GoogleReview />} />
                    <Route path='google-link' element={<GoogleLink />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default withContextProvider(App);
