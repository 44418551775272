/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type MerchantCreateInput = {
  dba: string,
  status: MerchantStatus,
  logo?: string | null,
  qrcode?: string | null,
  businessAddress?: AddressInput | null,
  businessPhone?: string | null,
  businessEmail?: string | null,
  businessHours?: BusinessHoursInput | null,
  processorMid?: string | null,
  processorName?: string | null,
  ownerName: string,
  ownerEmail?: string | null,
  ownerPhone?: string | null,
  ownerAddress?: AddressInput | null,
  checkinAppSetting?: CheckinAppSettingInput | null,
  sendReviewSmsEnabled?: boolean | null,
  sendReviewSmsMode?: ReviewSendMode | null,
  sendReviewSmsDelay?: number | null,
  sendReviewTriggerStatus?: OrderStatus | null,
  timezone?: string | null,
  reviewWebappTemplate?: string | null,
  badReviewAlertTemplate?: string | null,
};

export enum MerchantStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}


export type AddressInput = {
  name?: string | null,
  street1?: string | null,
  street2?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country?: string | null,
};

export type BusinessHoursInput = {
  monday?: WorkTimeRangeInput | null,
  tuesday?: WorkTimeRangeInput | null,
  wednesday?: WorkTimeRangeInput | null,
  thursday?: WorkTimeRangeInput | null,
  friday?: WorkTimeRangeInput | null,
  saturday?: WorkTimeRangeInput | null,
  sunday?: WorkTimeRangeInput | null,
};

export type WorkTimeRangeInput = {
  open?: string | null,
  close?: string | null,
};

export type CheckinAppSettingInput = {
  allowUserSelectService?: boolean | null,
  allowGuestCheckin?: boolean | null,
  allowUserSelectAppointment?: boolean | null,
  allowUserSelectTechnician?: boolean | null,
  showCustomAdvertisement?: boolean | null,
  customAdvertisementContent?: string | null,
};

// ###################################
// # Merchant
// ###################################
export enum ReviewSendMode {
  AUTO_SEND = "AUTO_SEND",
  MANUAL_SEND = "MANUAL_SEND",
}


// when making change on these status please review create/update get/list Order routes
export enum OrderStatus {
  WAITING = "WAITING",
  SERVICING = "SERVICING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  REFUNDED = "REFUNDED",
  SETTLED = "SETTLED",
}


export type CreatedId = {
  __typename: "CreatedId",
  id: string,
};

export type MerchantUpdateInput = {
  id: string,
  dba?: string | null,
  status?: MerchantStatus | null,
  logo?: string | null,
  qrcode?: string | null,
  businessAddress?: AddressInput | null,
  businessPhone?: string | null,
  businessEmail?: string | null,
  businessHours?: BusinessHoursInput | null,
  processorMid?: string | null,
  processorName?: string | null,
  ownerName?: string | null,
  ownerEmail?: string | null,
  ownerPhone?: string | null,
  ownerAddress?: AddressInput | null,
  checkinAppSetting?: CheckinAppSettingInput | null,
  sendReviewSmsEnabled?: boolean | null,
  sendReviewSmsMode?: ReviewSendMode | null,
  sendReviewSmsDelay?: number | null,
  sendReviewTriggerStatus?: OrderStatus | null,
  timezone?: string | null,
  googleAccount?: string | null,
  googleLocation?: string | null,
  reviewWebappTemplate?: string | null,
  badReviewAlertRecipients?: Array< BadAlertReviewRecipientInput | null > | null,
};

export type BadAlertReviewRecipientInput = {
  employeeId: string,
  smsEnabled: boolean,
  emailEnabled: boolean,
};

export type MerchantUpdateGoogleReviewUrl = {
  id: string,
  googleReviewUrl?: string | null,
};

export type CategoryCreateInput = {
  name: string,
  merchantId: string,
  rank?: number | null,
};

export type CategoryUpdateInput = {
  id: string,
  name: string,
  rank?: number | null,
};

export type CategoryRankUpdateInput = {
  id: string,
  rank: number,
};

export type ServiceCreateInput = {
  name: string,
  amount: number,
  merchantId: string,
  categoryId?: string | null,
  categoryName?: string | null,
  label?: string | null,
  rank?: number | null,
  isAmountUp?: boolean | null,
  duration?: number | null,
  description?: string | null,
};

export type ServiceUpdateInput = {
  id: string,
  name?: string | null,
  amount?: number | null,
  categoryId?: string | null,
  categoryName?: string | null,
  label?: string | null,
  rank?: number | null,
  isAmountUp?: boolean | null,
  duration?: number | null,
  description?: string | null,
};

export type ServiceRankUpdateInput = {
  id: string,
  rank?: number | null,
};

export type DiscountCreateInput = {
  merchantId: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  description?: string | null,
};

export enum OfferUnitType {
  DOLLAR = "DOLLAR",
  PERCENT = "PERCENT",
}


export type DiscountUpdateInput = {
  id: string,
  merchantId: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  description?: string | null,
};

export type PromoCreateInput = {
  merchantId: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  description?: string | null,
};

export type PromoUpdateInput = {
  id: string,
  merchantId: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  description?: string | null,
};

export type EmployeeCreateInput = {
  merchantId: string,
  firstName: string,
  lastName: string,
  email?: string | null,
  phone?: string | null,
  username?: string | null,
  publicName?: string | null,
  qrcode?: string | null,
  address?: AddressInput | null,
  birthdate?: string | null,
  image?: string | null,
  status?: EmployeeStatus | null,
  role?: EmployeeRoleType | null,
  ratingStars?: number | null,
  available?: boolean | null,
  color?: string | null,
  noCognitoUser?: boolean | null,
  workHours?: EmployeeHoursInput | null,
};

export enum EmployeeStatus {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
}


export enum EmployeeRoleType {
  OWNER = "OWNER",
  MANAGER = "MANAGER",
  SUPERVISOR = "SUPERVISOR",
  RECEPTIONIST = "RECEPTIONIST",
  TECHNICIAN = "TECHNICIAN",
}


export type EmployeeHoursInput = {
  monday?: EmployeeWorkTimeRangeInput | null,
  tuesday?: EmployeeWorkTimeRangeInput | null,
  wednesday?: EmployeeWorkTimeRangeInput | null,
  thursday?: EmployeeWorkTimeRangeInput | null,
  friday?: EmployeeWorkTimeRangeInput | null,
  saturday?: EmployeeWorkTimeRangeInput | null,
  sunday?: EmployeeWorkTimeRangeInput | null,
};

export type EmployeeWorkTimeRangeInput = {
  start?: string | null,
  end?: string | null,
};

export type EmployeeUpdateInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  username?: string | null,
  publicName?: string | null,
  qrcode?: string | null,
  address?: AddressInput | null,
  birthdate?: string | null,
  image?: string | null,
  status?: EmployeeStatus | null,
  role?: EmployeeRoleType | null,
  ratingStars?: number | null,
  available?: boolean | null,
  color?: string | null,
  noCognitoUser?: boolean | null,
  pin?: string | null,
  workHours?: EmployeeHoursInput | null,
};

export type CustomerCreateInput = {
  merchantId: string,
  firstName: string,
  lastName: string,
  email?: string | null,
  phone?: string | null,
  username?: string | null,
  publicName?: string | null,
  qrcode?: string | null,
  address?: AddressInput | null,
  birthdate?: string | null,
  image?: string | null,
  status?: CustomerStatus | null,
  type?: CustomerType | null,
  rewardPoints?: number | null,
  note?: string | null,
  receiveSms?: boolean | null,
  receiveEmail?: boolean | null,
  disableReviewSms?: boolean | null,
};

export enum CustomerStatus {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
}


export enum CustomerType {
  GUEST = "GUEST",
  NEW = "NEW",
  REGULAR = "REGULAR",
  LOYAL = "LOYAL",
  VIP = "VIP",
  RISK = "RISK",
}


export type CustomerUpdateInput = {
  id: string,
  merchantId: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  username?: string | null,
  publicName?: string | null,
  qrcode?: string | null,
  address?: AddressInput | null,
  birthdate?: string | null,
  image?: string | null,
  status?: CustomerStatus | null,
  type?: CustomerType | null,
  rewardPoints?: number | null,
  note?: string | null,
  receiveSms?: boolean | null,
  receiveEmail?: boolean | null,
  disableReviewSms?: boolean | null,
};

export type OrderCreateInput = {
  merchant: OrderMerchantInput,
  customer?: OrderCustomerInput | null,
  amount: number,
  status: OrderStatus,
  tenderType?: TenderType | null,
  note?: string | null,
  appointment?: string | null,
  cardInfo?: string | null,
};

export type OrderMerchantInput = {
  id: string,
  dba: string,
};

export type OrderCustomerInput = {
  id?: string | null,
  publicName?: string | null,
  birthdate?: string | null,
};

export enum TenderType {
  CARD = "CARD",
  CASH = "CASH",
  GIFT = "GIFT",
  POINT = "POINT",
}


export type CreatedOrder = {
  __typename: "CreatedOrder",
  id: string,
  merchantId: string,
  number?: string | null,
};

export type OrderUpdateInput = {
  id: string,
  customer?: OrderCustomerInput | null,
  amount: number,
  status: OrderStatus,
  tenderType?: TenderType | null,
  note?: string | null,
  appointment?: string | null,
  cardInfo?: string | null,
};

export type OrderCustomerAddInput = {
  id: string,
  publicName: string,
  birthdate?: string | null,
  orderId: string,
};

export type CreatedKeys = {
  __typename: "CreatedKeys",
  PK: string,
  SK: string,
};

export type OrderTipAddInput = {
  id: string,
  code?: string | null,
  offerUnit: OfferUnitType,
  offerAmount: number,
  amount: number,
  description?: string | null,
  tenderType?: TipTenderType | null,
  orderId: string,
  merchantId: string,
  customerId?: string | null,
  employeeId?: string | null,
  employeeName?: string | null,
};

export enum TipTenderType {
  CARD = "CARD",
  CASH = "CASH",
}


export type OrderTipUpdateInput = {
  id: string,
  code?: string | null,
  offerUnit: OfferUnitType,
  offerAmount: number,
  amount: number,
  description?: string | null,
  tenderType?: TipTenderType | null,
  orderId: string,
  customerId?: string | null,
  employeeId?: string | null,
  employeeName?: string | null,
};

export type OrderDiscountAddInput = {
  id: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  amount: number,
  description?: string | null,
  orderId: string,
  merchantId: string,
  customerId?: string | null,
};

export type OrderDiscountUpdateInput = {
  id: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  amount: number,
  description?: string | null,
  orderId: string,
  customerId?: string | null,
};

export type OrderPromoAddInput = {
  id: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  amount: number,
  description?: string | null,
  orderId: string,
  merchantId: string,
  customerId?: string | null,
};

export type OrderPromoUpdateInput = {
  id: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  amount: number,
  description?: string | null,
  orderId: string,
  customerId?: string | null,
};

export type OrderServiceAddInput = {
  id: string,
  name: string,
  amount: number,
  orderId: string,
  categoryId?: string | null,
  categoryName?: string | null,
  label?: string | null,
  rank?: number | null,
  tenderType?: TenderType | null,
  employeeId?: string | null,
  employeeName?: string | null,
  merchantId: string,
  customerId?: string | null,
};

export type OrderServiceUpdateInput = {
  id: string,
  name: string,
  amount: number,
  orderId: string,
  categoryId?: string | null,
  categoryName?: string | null,
  label?: string | null,
  rank?: number | null,
  tenderType?: TenderType | null,
  employeeId?: string | null,
  employeeName?: string | null,
  customerId?: string | null,
};

export type ErrorLogInput = {
  merchantId: string,
  type: string,
  message?: string | null,
  payload?: string | null,
  loggedInUser?: string | null,
  localDate?: string | null,
};

export type DynamoDBEventInput = {
  id?: string | null,
  name?: string | null,
  source?: string | null,
  arn?: string | null,
};

export enum NotificationType {
  ORDER_CREATED = "ORDER_CREATED",
  ORDER_CHANGED = "ORDER_CHANGED",
  APPT_CREATED = "APPT_CREATED",
  APPT_CHANGED = "APPT_CHANGED",
}


export type NotificationOrder = {
  __typename: "NotificationOrder",
  id?: string | null,
  orderId?: string | null,
  merchantId?: string | null,
  entityType?: string | null,
  topic?: NotificationType | null,
  message?: Order | null,
  seen?: string | null,
  event?: DynamoDBEvent | null,
  timestamp?: number | null,
  createdAt?: string | null,
};

export type Order = {
  __typename: "Order",
  id: string,
  // ulid
  amount: number,
  tenderType?: TenderType | null,
  status: OrderStatus,
  merchant: OrderMerchant,
  customer?: OrderCustomer | null,
  note?: string | null,
  number?: string | null,
  appointment?: string | null,
  cardInfo?: string | null,
  reviewLinkCreationDate?: string | null,
  reviewSentDate?: string | null,
  reviewSentType?: ReviewSentType | null,
  reviewId?: string | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type OrderMerchant = {
  __typename: "OrderMerchant",
  id: string,
  dba: string,
};

export type OrderCustomer = {
  __typename: "OrderCustomer",
  id?: string | null,
  merchantId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  publicName?: string | null,
  qrcode?: string | null,
  address?: Address | null,
  birthdate?: string | null,
  image?: string | null,
  status?: CustomerStatus | null,
  type?: CustomerType | null,
  rewardPoints?: number | null,
  visits?: number | null,
  note?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type Address = {
  __typename: "Address",
  name?: string | null,
  street1?: string | null,
  street2?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country?: string | null,
};

// ###################################
// # Order
// ###################################
export enum ReviewSentType {
  SMS = "SMS",
  EMAIL = "EMAIL",
}


export type DynamoDBEvent = {
  __typename: "DynamoDBEvent",
  id?: string | null,
  name?: string | null,
  source?: string | null,
  arn?: string | null,
};

// ###################################
// # Appointment
// ###################################
export enum AppointmentStatus {
  SCHEDULED = "SCHEDULED",
  ARRIVED = "ARRIVED",
  CANCELED = "CANCELED",
  NO_SHOW = "NO_SHOW",
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
}


export type AppointmentMerchantInput = {
  id: string,
  dba: string,
};

export type AppointmentCustomerInput = {
  id?: string | null,
  // ulid
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  birthdate?: string | null,
};

export type NotificationAppointment = {
  __typename: "NotificationAppointment",
  id?: string | null,
  appointmentId?: string | null,
  merchantId?: string | null,
  entityType?: string | null,
  topic?: NotificationType | null,
  message?: Appointment | null,
  seen?: string | null,
  event?: DynamoDBEvent | null,
  timestamp?: number | null,
  createdAt?: string | null,
};

export type Appointment = {
  __typename: "Appointment",
  id: string,
  // ulid
  startTime?: string | null,
  // startTime from the first service
  endTime?: string | null,
  // endTime from the last service
  status?: AppointmentStatus | null,
  merchant: AppointmentMerchant,
  customer?: AppointmentCustomer | null,
  amount?: number | null,
  note?: string | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type AppointmentMerchant = {
  __typename: "AppointmentMerchant",
  id: string,
  dba: string,
};

export type AppointmentCustomer = {
  __typename: "AppointmentCustomer",
  id?: string | null,
  // ulid
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  birthdate?: string | null,
};

export type CampaignCreateInput = {
  merchantId: string,
  dba?: string | null,
  name: string,
  channel: CampaignChannel,
  type: CampaignType,
  frequency?: CampaignFrequency | null,
  startDate?: string | null,
  endDate?: string | null,
  promoId?: string | null,
  templateMessage?: string | null,
  rules?: Array< CampaignRuleInput | null > | null,
};

// ###################################
// # Campaign
// ###################################
export enum CampaignChannel {
  ALL = "ALL",
  SMS = "SMS",
  EMAIL = "EMAIL",
}


export enum CampaignType {
  IMMEDIATE = "IMMEDIATE",
  SCHEDULED = "SCHEDULED",
}


export enum CampaignFrequency {
  ONCE = "ONCE",
  HOURLY = "HOURLY",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}


export type CampaignRuleInput = {
  name?: string | null,
  entityType: string,
  fieldPath: string,
  fieldType: CampaignRuleFieldType,
  valueType: CampaignRuleValueType,
  operation: CampaignRuleOperation,
  value: string,
};

export enum CampaignRuleFieldType {
  STRING = "STRING",
  INTEGER = "INTEGER",
  FLOAT = "FLOAT",
  DATE = "DATE",
  BIRTHDAY = "BIRTHDAY",
}


export enum CampaignRuleValueType {
  STRING = "STRING",
  INTEGER = "INTEGER",
  FLOAT = "FLOAT",
  DATE = "DATE",
  STRING_ARRAY = "STRING_ARRAY",
  INTEGER_ARRAY = "INTEGER_ARRAY",
  FLOAT_ARRAY = "FLOAT_ARRAY",
  DATE_ARRAY = "DATE_ARRAY",
  RANGE = "RANGE",
  BIRTHDAY_SELECTOR = "BIRTHDAY_SELECTOR",
}


export enum CampaignRuleOperation {
  EQUAL = "EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
  GREATER_THAN = "GREATER_THAN",
  LOWER_THAN = "LOWER_THAN",
  GREATER_EQUAL_THAN = "GREATER_EQUAL_THAN",
  LOWER_EQUAL_THAN = "LOWER_EQUAL_THAN",
  INCLUDE = "INCLUDE",
  EXCLUDE = "EXCLUDE",
  RANGE = "RANGE",
}


export type CampaignUpdateInput = {
  merchantId: string,
  dba?: string | null,
  id: string,
  name?: string | null,
  channel?: CampaignChannel | null,
  type: CampaignType,
  status?: CampaignStatus | null,
  frequency?: CampaignFrequency | null,
  startDate?: string | null,
  endDate?: string | null,
  promoId?: string | null,
  templateMessage?: string | null,
  rules?: Array< CampaignRuleInput | null > | null,
};

export enum CampaignStatus {
  CREATED = "CREATED",
  SCHEDULED = "SCHEDULED",
  PAUSED = "PAUSED",
  RUNNING = "RUNNING",
  SENT = "SENT",
}


export type ReviewCreateInput = {
  merchantId: string,
  type: ReviewType,
  id: string,
  orderId: string,
  customerId: string,
  customerFirstName?: string | null,
  customerLastName?: string | null,
  customerEmail?: string | null,
  customerPhone?: string | null,
  message?: string | null,
  orderRating?: number | null,
  serviceRatings?: Array< ServiceReviewRatingInput | null > | null,
};

// ###################################
// # Review
// ###################################
export enum ReviewType {
  LOYALTER = "LOYALTER",
}


export type ServiceReviewRatingInput = {
  id: string,
  name: string,
  amount: number,
  categoryId?: string | null,
  categoryName?: string | null,
  label?: string | null,
  rank?: number | null,
  employeeId?: string | null,
  employeeName?: string | null,
  rating: number,
};

export type ReviewUpdateInput = {
  merchantId: string,
  type: ReviewType,
  id: string,
  orderId: string,
  customerId: string,
  customerFirstName?: string | null,
  customerLastName?: string | null,
  customerEmail?: string | null,
  customerPhone?: string | null,
  message?: string | null,
  orderRating?: number | null,
  serviceRatings?: Array< ServiceReviewRatingInput | null > | null,
};

export type ReminderCreateInput = {
  merchantId: string,
  promoCode: string,
  promoDescription?: string | null,
  promoOfferUnit: OfferUnitType,
  promoOfferAmount: number,
  threshold: number,
  customerTypes: Array< CustomerType | null >,
  message: string,
  reminderStatus: ReminderStatus,
};

// ###################################
// # Reminder
// ###################################
export enum ReminderStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type ReminderUpdateInput = {
  id: string,
  merchantId: string,
  threshold: number,
  customerTypes: Array< CustomerType | null >,
  reminderStatus: ReminderStatus,
  message: string,
};

export type BirthdayCreateInput = {
  merchantId: string,
  promoCode: string,
  promoDescription?: string | null,
  promoOfferUnit: OfferUnitType,
  promoOfferAmount: number,
  threshold: number,
  customerTypes: Array< CustomerType | null >,
  reminderStatus: ReminderStatus,
  message: string,
};

export type BirthdayUpdateInput = {
  id: string,
  merchantId: string,
  threshold: number,
  customerTypes: Array< CustomerType | null >,
  reminderStatus: ReminderStatus,
  message: string,
};

export type CognitoUserAttributeInput = {
  name: string,
  value?: string | null,
};

export type AppointmentCreateInput = {
  startTime: string,
  endTime: string,
  status: AppointmentStatus,
  merchant: AppointmentMerchantInput,
  customer?: AppointmentCustomerInput | null,
  amount?: number | null,
  note?: string | null,
};

export type CreatedAppointment = {
  __typename: "CreatedAppointment",
  id: string,
  merchantId: string,
};

export type AppointmentUpdateInput = {
  id: string,
  startTime?: string | null,
  endTime?: string | null,
  status?: AppointmentStatus | null,
  merchant: AppointmentMerchantInput,
  customer?: AppointmentCustomerInput | null,
  amount?: number | null,
  note?: string | null,
};

export type AppointmentServiceAddInput = {
  id: string,
  apptId?: string | null,
  startTime?: string | null,
  // service start time
  endTime?: string | null,
  // service end time
  name: string,
  amount: number,
  categoryId?: string | null,
  categoryName?: string | null,
  employeeId?: string | null,
  employeeName?: string | null,
  merchantId: string,
  customerId?: string | null,
};

export type AppointmentServiceUpdateInput = {
  id: string,
  // serviceId
  apptId?: string | null,
  startTime?: string | null,
  // service start time
  endTime?: string | null,
  // service end time
  name: string,
  amount: number,
  categoryId?: string | null,
  categoryName?: string | null,
  employeeId?: string | null,
  employeeName?: string | null,
  merchantId: string,
  customerId?: string | null,
};

export type Merchant = {
  __typename: "Merchant",
  id: string,
  entityType: string,
  merchantId: string,
  dba: string,
  status?: MerchantStatus | null,
  logo?: string | null,
  qrcode?: string | null,
  businessAddress?: Address | null,
  businessPhone?: string | null,
  businessEmail?: string | null,
  businessHours?: BusinessHours | null,
  processorMid?: string | null,
  processorName?: string | null,
  ownerName: string,
  ownerEmail?: string | null,
  ownerPhone?: string | null,
  ownerAddress?: Address | null,
  checkinAppSetting?: CheckinAppSetting | null,
  sendReviewSmsEnabled?: boolean | null,
  sendReviewSmsMode?: ReviewSendMode | null,
  sendReviewSmsDelay?: number | null,
  sendReviewTriggerStatus?: OrderStatus | null,
  reviewWebappTemplate?: string | null,
  isAssociatedToGoogle?: boolean | null,
  googleAccount?: string | null,
  googleLocation?: string | null,
  googleReviewUrl?: string | null,
  badReviewAlertRecipients?:  Array<BadAlertReviewRecipient | null > | null,
  timezone?: string | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type BusinessHours = {
  __typename: "BusinessHours",
  monday?: WorkTimeRange | null,
  tuesday?: WorkTimeRange | null,
  wednesday?: WorkTimeRange | null,
  thursday?: WorkTimeRange | null,
  friday?: WorkTimeRange | null,
  saturday?: WorkTimeRange | null,
  sunday?: WorkTimeRange | null,
};

export type WorkTimeRange = {
  __typename: "WorkTimeRange",
  open?: string | null,
  close?: string | null,
};

export type CheckinAppSetting = {
  __typename: "CheckinAppSetting",
  allowUserSelectService?: boolean | null,
  allowGuestCheckin?: boolean | null,
  allowUserSelectAppointment?: boolean | null,
  allowUserSelectTechnician?: boolean | null,
  showCustomAdvertisement?: boolean | null,
  customAdvertisementContent?: string | null,
};

export type BadAlertReviewRecipient = {
  __typename: "BadAlertReviewRecipient",
  employeeId: string,
  smsEnabled: boolean,
  emailEnabled: boolean,
};

export type MerchantPublic = {
  __typename: "MerchantPublic",
  id: string,
  dba: string,
  logo?: string | null,
  businessAddress?: Address | null,
  businessPhone?: string | null,
  businessEmail?: string | null,
  businessHours?: BusinessHours | null,
};

export type MerchantPage = {
  __typename: "MerchantPage",
  merchants?:  Array<Merchant > | null,
  nextToken?: string | null,
};

export type GoogleOauthResponse = {
  __typename: "GoogleOauthResponse",
  access_token?: string | null,
  expires_in?: number | null,
  scope?: string | null,
  token_type?: string | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  name: string,
  merchantId: string,
  rank?: number | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type CategoryPage = {
  __typename: "CategoryPage",
  categories?:  Array<Category > | null,
  nextToken?: string | null,
};

export type Service = {
  __typename: "Service",
  id: string,
  // ulid
  name: string,
  amount: number,
  merchantId: string,
  categoryId?: string | null,
  categoryName?: string | null,
  label?: string | null,
  rank?: number | null,
  isAmountUp?: boolean | null,
  duration?: number | null,
  description?: string | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ServicePage = {
  __typename: "ServicePage",
  services?:  Array<Service > | null,
  nextToken?: string | null,
};

export type ServicePagePublic = {
  __typename: "ServicePagePublic",
  services?:  Array<ServicePublic > | null,
  nextToken?: string | null,
};

export type ServicePublic = {
  __typename: "ServicePublic",
  id: string,
  // ulid
  name: string,
  amount: number,
  merchantId: string,
  categoryId?: string | null,
  categoryName?: string | null,
  label?: string | null,
  rank?: number | null,
  isAmountUp?: boolean | null,
  duration?: number | null,
  description?: string | null,
  createdAt?: string | null,
};

export type Discount = {
  __typename: "Discount",
  id: string,
  // ulid
  merchantId: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  description?: string | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DiscountPage = {
  __typename: "DiscountPage",
  discounts?:  Array<Discount > | null,
  nextToken?: string | null,
};

export type Promo = {
  __typename: "Promo",
  id: string,
  // ulid
  merchantId: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  description?: string | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type PromoPage = {
  __typename: "PromoPage",
  promos?:  Array<Promo > | null,
  nextToken?: string | null,
};

export type Employee = {
  __typename: "Employee",
  id: string,
  merchantId: string,
  firstName: string,
  lastName: string,
  email?: string | null,
  phone?: string | null,
  username?: string | null,
  publicName?: string | null,
  qrcode?: string | null,
  address?: Address | null,
  birthdate?: string | null,
  image?: string | null,
  status?: EmployeeStatus | null,
  role?: EmployeeRoleType | null,
  ratingStars?: number | null,
  available?: boolean | null,
  color?: string | null,
  noCognitoUser?: boolean | null,
  pin?: string | null,
  workHours?: EmployeeHours | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type EmployeeHours = {
  __typename: "EmployeeHours",
  monday?: EmployeeWorkTimeRange | null,
  tuesday?: EmployeeWorkTimeRange | null,
  wednesday?: EmployeeWorkTimeRange | null,
  thursday?: EmployeeWorkTimeRange | null,
  friday?: EmployeeWorkTimeRange | null,
  saturday?: EmployeeWorkTimeRange | null,
  sunday?: EmployeeWorkTimeRange | null,
};

export type EmployeeWorkTimeRange = {
  __typename: "EmployeeWorkTimeRange",
  start?: string | null,
  end?: string | null,
};

export type EmployeePage = {
  __typename: "EmployeePage",
  employees?:  Array<Employee > | null,
  nextToken?: string | null,
};

export type EmployeePagePublic = {
  __typename: "EmployeePagePublic",
  employees?:  Array<EmployeePublic > | null,
  nextToken?: string | null,
};

export type EmployeePublic = {
  __typename: "EmployeePublic",
  id: string,
  merchantId: string,
  firstName?: string | null,
  publicName?: string | null,
  workHours?: EmployeeHours | null,
};

export type Customer = {
  __typename: "Customer",
  id: string,
  merchantId: string,
  firstName: string,
  lastName: string,
  email?: string | null,
  phone?: string | null,
  username?: string | null,
  publicName?: string | null,
  qrcode?: string | null,
  address?: Address | null,
  birthdate?: string | null,
  image?: string | null,
  status?: CustomerStatus | null,
  type?: CustomerType | null,
  rewardPoints?: number | null,
  visits?: number | null,
  note?: string | null,
  tags?: Array< string > | null,
  receiveSms?: boolean | null,
  receiveEmail?: boolean | null,
  disableReviewSms?: boolean | null,
  lastReviewOpenedAt?: string | null,
  googleReviewButtonClickedAt?: string | null,
  googleReviewCreatedAt?: string | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type CustomerPublic = {
  __typename: "CustomerPublic",
  id: string,
  merchantId: string,
  firstName: string,
  lastName: string,
  phone?: string | null,
  publicName?: string | null,
  createdAt?: string | null,
};

export type CustomerPage = {
  __typename: "CustomerPage",
  customers?:  Array<Customer > | null,
  nextToken?: string | null,
};

export type SearchCustomersFilters = {
  email_filter?: string | null,
  firstname_filter?: string | null,
  lastname_filter?: string | null,
  phone_filter?: string | null,
};

export type SearchCustomersQueryOptions = {
  queryId: string,
  cursor: string,
};

export type CustomerSearchResult = {
  __typename: "CustomerSearchResult",
  customers?:  Array<Customer > | null,
  total: number,
  queryId?: string | null,
  startCursor?: string | null,
  nextCursor?: string | null,
};

export enum CustomerValidationType {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
}


export type OrderDetail = {
  __typename: "OrderDetail",
  id: string,
  // ulid
  amount: number,
  tenderType?: TenderType | null,
  status: OrderStatus,
  merchant: OrderMerchant,
  customer?: OrderCustomer | null,
  note?: string | null,
  number?: string | null,
  appointment?: string | null,
  cardInfo?: string | null,
  reviewLinkCreationDate?: string | null,
  reviewSentDate?: string | null,
  reviewSentType?: ReviewSentType | null,
  reviewId?: string | null,
  services?:  Array<OrderService | null > | null,
  promos?:  Array<OrderPromo | null > | null,
  discounts?:  Array<OrderDiscount | null > | null,
  redeems?:  Array<OrderRedeem | null > | null,
  taxes?:  Array<OrderTax | null > | null,
  tips?:  Array<OrderTip | null > | null,
  transactions?:  Array<OrderTransaction | null > | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type OrderService = {
  __typename: "OrderService",
  id: string,
  name: string,
  amount: number,
  categoryId?: string | null,
  categoryName?: string | null,
  label?: string | null,
  rank?: number | null,
  tenderType?: TenderType | null,
  employeeId?: string | null,
  employeeName?: string | null,
};

export type OrderPromo = {
  __typename: "OrderPromo",
  id: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  amount: number,
  description?: string | null,
};

export type OrderDiscount = {
  __typename: "OrderDiscount",
  id: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  amount: number,
  description?: string | null,
};

export type OrderRedeem = {
  __typename: "OrderRedeem",
  id: string,
  // ulid
  points: number,
  amount?: number | null,
};

export type OrderTax = {
  __typename: "OrderTax",
  id: string,
  code: string,
  offerUnit: OfferUnitType,
  offerAmount: number,
  amount: number,
  description?: string | null,
};

export type OrderTip = {
  __typename: "OrderTip",
  id: string,
  code?: string | null,
  offerUnit: OfferUnitType,
  offerAmount: number,
  amount: number,
  description?: string | null,
  tenderType?: TipTenderType | null,
  employeeId?: string | null,
  employeeName?: string | null,
};

export type OrderTransaction = {
  __typename: "OrderTransaction",
  id: string,
  mid: string,
  amount: number,
  status: TransactionStatus,
  paymentType: PaymentType,
  processor: string,
};

export enum TransactionStatus {
  PENDING = "PENDING",
  READY = "READY",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  REFUNDED = "REFUNDED",
}


export enum PaymentType {
  CASH = "CASH",
  CHECK = "CHECK",
  VISA = "VISA",
  MASTER = "MASTER",
  AMEX = "AMEX",
  DISCO = "DISCO",
  GIFT = "GIFT",
  POINT = "POINT",
  OTHER = "OTHER",
}


// when making change on these status please review create/update get/list Order routes
export enum OrderStatusFilter {
  ALL_GROUP = "ALL_GROUP",
  IN_PROGRESS_GROUP = "IN_PROGRESS_GROUP",
  FINISHED_GROUP = "FINISHED_GROUP",
  WAITING = "WAITING",
  SERVICING = "SERVICING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  REFUNDED = "REFUNDED",
  SETTLED = "SETTLED",
}


export type OrderPage = {
  __typename: "OrderPage",
  orders?:  Array<Order > | null,
  nextToken?: string | null,
};

export type OrderSearchResult = {
  __typename: "OrderSearchResult",
  orders?:  Array<OrderDetail > | null,
  total: number,
};

export enum StatisticType {
  SUM = "SUM",
  DATE = "DATE",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}


export type StatisticPage = {
  __typename: "StatisticPage",
  statistics?:  Array<Statistic > | null,
  nextToken?: string | null,
};

export type Statistic = {
  __typename: "Statistic",
  PK: string,
  // PK MERCHANT#id
  SK: string,
  // SK in form [SUM, WEEK#startDate, MONTH#startDate, YEAR#startDate, DATE#startDate]  startDate in form YYYY-MM-DD local time
  id: string,
  // combination of PK and SK [MERCHANT#mid#DATE#startDate]
  startDate: string,
  // YYYY-MM-DD
  entityType?: string | null,
  // [SUM, MONDAY...SUNDAY, WEEK1...WEEK52, MONTH1...MONTH12, YEAR]
  merchantId: string,
  cardAmount?: number | null,
  cardTipAmount?: number | null,
  cardTotal?: number | null,
  cashAmount?: number | null,
  cashTipAmount?: number | null,
  cashTotal?: number | null,
  allTotal?: number | null,
  orderCounts?: number | null,
  orderNumberCounter?: number | null,
  customerCounts?: number | null,
  employeeCounts?: number | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export enum groupDateType {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}


export type OrderStatisticsResult = {
  __typename: "OrderStatisticsResult",
  merchantId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  groupDateType?: groupDateType | null,
  results?:  Array<OrderStatistics > | null,
};

export type OrderStatistics = {
  __typename: "OrderStatistics",
  PK?: string | null,
  // PK MERCHANT#id
  SK?: string | null,
  // SK in form [ORDER_STATS#WEEK#groupDate, ORDER_STATS#MONTH#groupDate, ORDER_STATS#YEAR#groupDate, ORDER_STATS#DAY#groupDate]  groupDate in form YYYY-MM-DD local time
  id?: string | null,
  // combination of PK and SK [MERCHANT#mid#ORDER_STATS#DAY#groupDate]
  entityType?: string | null,
  // [ORDER_STATS, REVIEW_STATS, CUSTOMER_STATS, EMPLOYEE_STATS]
  dateType?: string | null,
  // [MONDAY...SUNDAY, WEEK01...WEEK52, MONTH01...MONTH12, YEAR2022]
  merchantId: string,
  groupDate: string,
  groupDateType: groupDateType,
  cardOrderAmount?: number | null,
  cardTipAmount?: number | null,
  cardTotal?: number | null,
  cashOrderAmount?: number | null,
  cashTipAmount?: number | null,
  cashTotal?: number | null,
  cashCounts?: number | null,
  cardCounts?: number | null,
  tipCounts?: number | null,
  allCounts?: number | null,
  tipTotal?: number | null,
  allTotal?: number | null,
  orderCounts?: number | null,
  // will deprecate when refactor merchant app
  highestOrderAmount?: number | null,
  lowestOrderAmount?: number | null,
  averageOrderAmount?: number | null,
  highestTipAmount?: number | null,
  lowestTipAmount?: number | null,
  averageTipAmount?: number | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type EmployeeStatisticsResult = {
  __typename: "EmployeeStatisticsResult",
  merchantId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  groupDateType?: groupDateType | null,
  results?:  Array<EmployeeStatistics > | null,
};

export type EmployeeStatistics = {
  __typename: "EmployeeStatistics",
  PK?: string | null,
  // PK MERCHANT#id
  SK?: string | null,
  // SK in form [EMPLOYEE_STATS#WEEK#groupDate, EMPLOYEE_STATS#MONTH#groupDate, EMPLOYEE_STATS#YEAR#groupDate, EMPLOYEE_STATS#DAY#groupDate]  groupDate in form YYYY-MM-DD local time
  id?: string | null,
  // combination of PK and SK [MERCHANT#mid#EMPLOYEE_STATS#DAY#groupDate]
  entityType?: string | null,
  // [EMPLOYEE_STATS, REVIEW_STATS, CUSTOMER_STATS, EMPLOYEE_STATS]
  dateType?: string | null,
  // [MONDAY...SUNDAY, WEEK01...WEEK52, MONTH01...MONTH12, YEAR2022]
  merchantId: string,
  employeeId?: string | null,
  employeeName?: string | null,
  groupDate: string,
  groupDateType: groupDateType,
  serviceAllCounts?: number | null,
  // will deprecate when refactor merchant app
  serviceCardCounts?: number | null,
  serviceCashCounts?: number | null,
  serviceCardAmount?: number | null,
  serviceCashAmount?: number | null,
  serviceTotal?: number | null,
  tipAllCounts?: number | null,
  tipCardCounts?: number | null,
  tipCashCounts?: number | null,
  tipCardAmount?: number | null,
  tipCashAmount?: number | null,
  tipTotal?: number | null,
  cardTotal?: number | null,
  cashTotal?: number | null,
  allTotal?: number | null,
  highestServiceAmount?: number | null,
  lowestServiceAmount?: number | null,
  averageServiceAmount?: number | null,
  highestTipAmount?: number | null,
  lowestTipAmount?: number | null,
  averageTipAmount?: number | null,
  summary?:  Array<EmployeeStatisticsSummaryItem > | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type EmployeeStatisticsSummaryItem = {
  __typename: "EmployeeStatisticsSummaryItem",
  PK?: string | null,
  // ulid
  SK?: string | null,
  entityType?: string | null,
  name?: string | null,
  amount?: number | null,
  tenderType?: TenderType | null,
  status?: OrderStatus | null,
  customerId?: string | null,
  customerName?: string | null,
  createdAt?: string | null,
};

export type CustomerTypeCountsResult = {
  __typename: "CustomerTypeCountsResult",
  merchantId?: string | null,
  results?:  Array<CustomerTypeCountsItem | null > | null,
};

export type CustomerTypeCountsItem = {
  __typename: "CustomerTypeCountsItem",
  customerType?: CustomerType | null,
  counts?: number | null,
};

export type ReviewStatisticsResult = {
  __typename: "ReviewStatisticsResult",
  merchantId?: string | null,
  reviewType?: ReviewType | null,
  results?:  Array<ReviewStatistics | null > | null,
};

export type ReviewStatistics = {
  __typename: "ReviewStatistics",
  merchantId?: string | null,
  reviewType?: ReviewType | null,
  averageRating?: number | null,
  reviewCounts?: number | null,
};

export type CampaignPage = {
  __typename: "CampaignPage",
  campaigns?:  Array<Campaign > | null,
  nextToken?: string | null,
};

export type Campaign = {
  __typename: "Campaign",
  merchantId: string,
  dba?: string | null,
  id: string,
  name?: string | null,
  channel?: CampaignChannel | null,
  type: CampaignType,
  status?: CampaignStatus | null,
  frequency?: CampaignFrequency | null,
  startDate?: string | null,
  endDate?: string | null,
  promoId?: string | null,
  promoCode?: string | null,
  promoOfferUnit?: OfferUnitType | null,
  promoAmount?: number | null,
  promoDescription?: string | null,
  templateMessage?: string | null,
  rules?:  Array<CampaignRule | null > | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type CampaignRule = {
  __typename: "CampaignRule",
  name?: string | null,
  entityType: string,
  fieldPath: string,
  fieldType: CampaignRuleFieldType,
  valueType: CampaignRuleValueType,
  operation: CampaignRuleOperation,
  value: string,
};

export type Review = {
  __typename: "Review",
  merchantId: string,
  id: string,
  // ulid
  type?: ReviewType | null,
  orderId: string,
  customerId: string,
  customerFirstName?: string | null,
  customerLastName?: string | null,
  customerEmail?: string | null,
  customerPhone?: string | null,
  message?: string | null,
  orderRating?: number | null,
  serviceRatings?:  Array<ServiceReviewRating | null > | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ServiceReviewRating = {
  __typename: "ServiceReviewRating",
  id: string,
  name: string,
  amount: number,
  categoryId?: string | null,
  categoryName?: string | null,
  label?: string | null,
  rank?: number | null,
  employeeId?: string | null,
  employeeName?: string | null,
  rating: number,
};

export type ReviewPage = {
  __typename: "ReviewPage",
  reviews?:  Array<Review > | null,
  nextToken?: string | null,
};

export type Reminder = {
  __typename: "Reminder",
  id: string,
  merchantId: string,
  promotionId: string,
  threshold: number,
  totalDelivered: number,
  customerTypes: Array< CustomerType | null >,
  reminderStatus: ReminderStatus,
  message: string,
};

export type ReminderPage = {
  __typename: "ReminderPage",
  reminders?:  Array<Reminder > | null,
  nextToken?: string | null,
};

export type Birthday = {
  __typename: "Birthday",
  id: string,
  merchantId: string,
  promotionId: string,
  threshold: number,
  totalDelivered: number,
  customerTypes: Array< CustomerType | null >,
  reminderStatus: ReminderStatus,
  message: string,
};

export type BirthdayPage = {
  __typename: "BirthdayPage",
  birthdays?:  Array<Birthday > | null,
  nextToken?: string | null,
};

export type CognitoUserPage = {
  __typename: "CognitoUserPage",
  users?:  Array<CognitoUser > | null,
  nextToken?: string | null,
};

export type CognitoUser = {
  __typename: "CognitoUser",
  username: string,
  attributes?:  Array<CognitoUserAttribute > | null,
  createDate?: string | null,
  lastModifiedDate?: string | null,
  enabled: boolean,
  status: CognitoUserStatus,
};

export type CognitoUserAttribute = {
  __typename: "CognitoUserAttribute",
  name: string,
  value?: string | null,
};

// ###################################
// # Cognito User
// ###################################
export enum CognitoUserStatus {
  UNCONFIRMED = "UNCONFIRMED",
  CONFIRMED = "CONFIRMED",
  ARCHIVED = "ARCHIVED",
  COMPROMISED = "COMPROMISED",
  UNKNOWN = "UNKNOWN",
  RESET_REQUIRED = "RESET_REQUIRED",
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
}


export type AppointmentDetail = {
  __typename: "AppointmentDetail",
  id: string,
  // ulid
  startTime?: string | null,
  // startTime from the first service
  endTime?: string | null,
  // endTime from the last service
  status?: AppointmentStatus | null,
  merchant: AppointmentMerchant,
  customer?: AppointmentCustomer | null,
  amount?: number | null,
  note?: string | null,
  services?:  Array<AppointmentService | null > | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type AppointmentService = {
  __typename: "AppointmentService",
  id: string,
  startTime?: string | null,
  endTime?: string | null,
  name: string,
  amount: number,
  categoryId?: string | null,
  categoryName?: string | null,
  employeeId?: string | null,
  employeeName?: string | null,
};

export type AppointmentDetailPage = {
  __typename: "AppointmentDetailPage",
  appointments?:  Array<AppointmentDetail > | null,
};

export type AppointmentDetailPagePublic = {
  __typename: "AppointmentDetailPagePublic",
  appointments?:  Array<AppointmentDetailPublic > | null,
};

export type AppointmentDetailPublic = {
  __typename: "AppointmentDetailPublic",
  id: string,
  // ulid
  startTime?: string | null,
  // startTime from the first service
  endTime?: string | null,
  // endTime from the last service
  status?: AppointmentStatus | null,
  merchant: AppointmentMerchant,
  services?:  Array<AppointmentServicePublic | null > | null,
  createdAt?: string | null,
};

export type AppointmentServicePublic = {
  __typename: "AppointmentServicePublic",
  id: string,
  startTime?: string | null,
  endTime?: string | null,
  name: string,
  categoryId?: string | null,
  categoryName?: string | null,
};

export type EmployeeIncomeReportResult = {
  __typename: "EmployeeIncomeReportResult",
  incomeReports?:  Array<EmployeeIncomeDetailReport > | null,
};

export type EmployeeIncomeDetailReport = {
  __typename: "EmployeeIncomeDetailReport",
  employeeId?: string | null,
  employeeName?: string | null,
  employeeColor?: string | null,
  merchantId: string,
  startDate?: string | null,
  endDate?: string | null,
  dateLabel?: string | null,
  revenue?: number | null,
  commission?: number | null,
  tip?: number | null,
  Income?: number | null,
  cashIncome?: number | null,
  checkIncome?: number | null,
  detailByDay?:  Array<EmployeeServiceReportByDay > | null,
  detailByDateRange?:  Array<EmployeeServiceReportByDateRange > | null,
};

export type EmployeeServiceReportByDay = {
  __typename: "EmployeeServiceReportByDay",
  orderId?: string | null,
  orderNumber?: string | null,
  dateTime?: string | null,
  serviceName?: string | null,
  serviceAmount?: number | null,
  tipAmount?: number | null,
};

export type EmployeeServiceReportByDateRange = {
  __typename: "EmployeeServiceReportByDateRange",
  dateTime?: string | null,
  orderCounts?: number | null,
  totalServiceAmount?: number | null,
  totalTipAmount?: number | null,
};

export type CreateMerchantMutationVariables = {
  newMerchant: MerchantCreateInput,
};

export type CreateMerchantMutation = {
  // Using Merchant Table section
  createMerchant:  {
    __typename: "CreatedId",
    id: string,
  },
};

export type UpdateMerchantMutationVariables = {
  updateMerchant: MerchantUpdateInput,
};

export type UpdateMerchantMutation = {
  updateMerchant: boolean,
};

export type RevokeAccessTokenMutationVariables = {
  merchantId: string,
};

export type RevokeAccessTokenMutation = {
  revokeAccessToken?: boolean | null,
};

export type UpdateMerchantGoogleReviewUrlMutationVariables = {
  updateMerchant: MerchantUpdateGoogleReviewUrl,
};

export type UpdateMerchantGoogleReviewUrlMutation = {
  updateMerchantGoogleReviewUrl: boolean,
};

export type CreateCategoryMutationVariables = {
  newCategory: CategoryCreateInput,
};

export type CreateCategoryMutation = {
  // Merchant Category
  createCategory:  {
    __typename: "CreatedId",
    id: string,
  },
};

export type UpdateCategoryMutationVariables = {
  updateCategory: CategoryUpdateInput,
};

export type UpdateCategoryMutation = {
  updateCategory: boolean,
};

export type UpdateCategoryRanksMutationVariables = {
  updateCategoryRanks: Array< CategoryRankUpdateInput >,
};

export type UpdateCategoryRanksMutation = {
  updateCategoryRanks: boolean,
};

export type DeleteCategoryMutationVariables = {
  id: string,
};

export type DeleteCategoryMutation = {
  deleteCategory: boolean,
};

export type CreateServiceMutationVariables = {
  newService: ServiceCreateInput,
};

export type CreateServiceMutation = {
  // Merchant Service
  createService:  {
    __typename: "CreatedId",
    id: string,
  },
};

export type UpdateServiceMutationVariables = {
  updateService: ServiceUpdateInput,
};

export type UpdateServiceMutation = {
  updateService: boolean,
};

export type UpdateServiceRanksMutationVariables = {
  updateServiceRanks: Array< ServiceRankUpdateInput >,
};

export type UpdateServiceRanksMutation = {
  updateServiceRanks: boolean,
};

export type DeleteServiceMutationVariables = {
  id: string,
};

export type DeleteServiceMutation = {
  deleteService: boolean,
};

export type CreateDiscountMutationVariables = {
  newDiscount: DiscountCreateInput,
};

export type CreateDiscountMutation = {
  // Merchant Discount
  createDiscount:  {
    __typename: "CreatedId",
    id: string,
  },
};

export type UpdateDiscountMutationVariables = {
  updateDiscount: DiscountUpdateInput,
};

export type UpdateDiscountMutation = {
  updateDiscount: boolean,
};

export type DeleteDiscountMutationVariables = {
  id: string,
};

export type DeleteDiscountMutation = {
  deleteDiscount: boolean,
};

export type CreatePromoMutationVariables = {
  newPromo: PromoCreateInput,
};

export type CreatePromoMutation = {
  // Merchant Promo
  createPromo:  {
    __typename: "CreatedId",
    id: string,
  },
};

export type UpdatePromoMutationVariables = {
  updatePromo: PromoUpdateInput,
};

export type UpdatePromoMutation = {
  updatePromo: boolean,
};

export type DeletePromoMutationVariables = {
  id: string,
};

export type DeletePromoMutation = {
  deletePromo: boolean,
};

export type CreateEmployeeMutationVariables = {
  newEmployee: EmployeeCreateInput,
};

export type CreateEmployeeMutation = {
  // Using Employee Table section
  createEmployee:  {
    __typename: "CreatedId",
    id: string,
  },
};

export type UpdateEmployeeMutationVariables = {
  updateEmployee: EmployeeUpdateInput,
};

export type UpdateEmployeeMutation = {
  updateEmployee: boolean,
};

export type CreateCustomerMutationVariables = {
  newCustomer: CustomerCreateInput,
};

export type CreateCustomerMutation = {
  // Using Customer Table section
  createCustomer:  {
    __typename: "CreatedId",
    id: string,
  },
};

export type UpdateCustomerMutationVariables = {
  updateCustomer: CustomerUpdateInput,
};

export type UpdateCustomerMutation = {
  updateCustomer: boolean,
};

export type DeleteCustomerMutationVariables = {
  id: string,
};

export type DeleteCustomerMutation = {
  deleteCustomer: boolean,
};

export type AddCustomerTagMutationVariables = {
  customerId: string,
  tag: string,
};

export type AddCustomerTagMutation = {
  addCustomerTag: boolean,
};

export type DeleteCustomerTagMutationVariables = {
  customerId: string,
  tag: string,
};

export type DeleteCustomerTagMutation = {
  deleteCustomerTag: boolean,
};

export type SetGoogleReviewDoneMutationVariables = {
  customerId: string,
};

export type SetGoogleReviewDoneMutation = {
  setGoogleReviewDone: boolean,
};

export type SetGoogleReviewClickedMutationVariables = {
  customerId: string,
};

export type SetGoogleReviewClickedMutation = {
  setGoogleReviewClicked: boolean,
};

export type CreateOrderMutationVariables = {
  newOrder: OrderCreateInput,
};

export type CreateOrderMutation = {
  // Using Order Table section
  createOrder:  {
    __typename: "CreatedOrder",
    id: string,
    merchantId: string,
    number?: string | null,
  },
};

export type UpdateOrderMutationVariables = {
  updateOrder: OrderUpdateInput,
};

export type UpdateOrderMutation = {
  updateOrder: boolean,
};

export type DeleteOrderMutationVariables = {
  id: string,
};

export type DeleteOrderMutation = {
  deleteOrder: boolean,
};

export type AddOrderCustomerMutationVariables = {
  newOrderCustomer: OrderCustomerAddInput,
};

export type AddOrderCustomerMutation = {
  addOrderCustomer:  {
    __typename: "CreatedKeys",
    PK: string,
    SK: string,
  },
};

export type AddOrderTipMutationVariables = {
  newOrderTip: OrderTipAddInput,
};

export type AddOrderTipMutation = {
  addOrderTip:  {
    __typename: "CreatedKeys",
    PK: string,
    SK: string,
  },
};

export type UpdateOrderTipMutationVariables = {
  updateOrderTip: OrderTipUpdateInput,
};

export type UpdateOrderTipMutation = {
  updateOrderTip: boolean,
};

export type DeleteOrderTipMutationVariables = {
  orderId: string,
  tipId: string,
};

export type DeleteOrderTipMutation = {
  deleteOrderTip: boolean,
};

export type AddOrderDiscountMutationVariables = {
  newOrderDiscount: OrderDiscountAddInput,
};

export type AddOrderDiscountMutation = {
  addOrderDiscount:  {
    __typename: "CreatedKeys",
    PK: string,
    SK: string,
  },
};

export type UpdateOrderDiscountMutationVariables = {
  updateOrderDiscount: OrderDiscountUpdateInput,
};

export type UpdateOrderDiscountMutation = {
  updateOrderDiscount: boolean,
};

export type DeleteOrderDiscountMutationVariables = {
  orderId: string,
  discountId: string,
};

export type DeleteOrderDiscountMutation = {
  deleteOrderDiscount: boolean,
};

export type AddOrderPromoMutationVariables = {
  newOrderPromo: OrderPromoAddInput,
};

export type AddOrderPromoMutation = {
  addOrderPromo:  {
    __typename: "CreatedKeys",
    PK: string,
    SK: string,
  },
};

export type UpdateOrderPromoMutationVariables = {
  updateOrderPromo: OrderPromoUpdateInput,
};

export type UpdateOrderPromoMutation = {
  updateOrderPromo: boolean,
};

export type DeleteOrderPromoMutationVariables = {
  orderId: string,
  promoId: string,
};

export type DeleteOrderPromoMutation = {
  deleteOrderPromo: boolean,
};

export type AddOrderServiceMutationVariables = {
  newOrderService: OrderServiceAddInput,
};

export type AddOrderServiceMutation = {
  addOrderService:  {
    __typename: "CreatedKeys",
    PK: string,
    SK: string,
  },
};

export type UpdateOrderServiceMutationVariables = {
  updateOrderService: OrderServiceUpdateInput,
};

export type UpdateOrderServiceMutation = {
  updateOrderService: boolean,
};

export type DeleteOrderServiceMutationVariables = {
  orderId: string,
  serviceId: string,
};

export type DeleteOrderServiceMutation = {
  deleteOrderService: boolean,
};

export type CreateErrorLogMutationVariables = {
  newError: ErrorLogInput,
};

export type CreateErrorLogMutation = {
  // Using Error Table section
  createErrorLog:  {
    __typename: "CreatedId",
    id: string,
  },
};

export type NotifyOrderChangedMutationVariables = {
  id: string,
  amount: number,
  status: OrderStatus,
  tenderType?: TenderType | null,
  number?: string | null,
  appointment?: string | null,
  cardInfo?: string | null,
  merchant: OrderMerchantInput,
  customer?: OrderCustomerInput | null,
  event?: DynamoDBEventInput | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  topic?: NotificationType | null,
};

export type NotifyOrderChangedMutation = {
  // Using Subscription
  notifyOrderChanged:  {
    __typename: "NotificationOrder",
    id?: string | null,
    orderId?: string | null,
    merchantId?: string | null,
    entityType?: string | null,
    topic?: NotificationType | null,
    message?:  {
      __typename: "Order",
      id: string,
      // ulid
      amount: number,
      tenderType?: TenderType | null,
      status: OrderStatus,
      merchant:  {
        __typename: "OrderMerchant",
        id: string,
        dba: string,
      },
      customer?:  {
        __typename: "OrderCustomer",
        id?: string | null,
        merchantId?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        publicName?: string | null,
        qrcode?: string | null,
        address?:  {
          __typename: "Address",
          name?: string | null,
          street1?: string | null,
          street2?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        birthdate?: string | null,
        image?: string | null,
        status?: CustomerStatus | null,
        type?: CustomerType | null,
        rewardPoints?: number | null,
        visits?: number | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      note?: string | null,
      number?: string | null,
      appointment?: string | null,
      cardInfo?: string | null,
      reviewLinkCreationDate?: string | null,
      reviewSentDate?: string | null,
      reviewSentType?: ReviewSentType | null,
      reviewId?: string | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null,
    seen?: string | null,
    event?:  {
      __typename: "DynamoDBEvent",
      id?: string | null,
      name?: string | null,
      source?: string | null,
      arn?: string | null,
    } | null,
    timestamp?: number | null,
    createdAt?: string | null,
  },
};

export type NotifyAppointmentChangedMutationVariables = {
  id: string,
  startTime?: string | null,
  endTime?: string | null,
  status?: AppointmentStatus | null,
  merchant: AppointmentMerchantInput,
  customer?: AppointmentCustomerInput | null,
  event?: DynamoDBEventInput | null,
  amount?: number | null,
  note?: string | null,
  timestamp?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  topic?: NotificationType | null,
};

export type NotifyAppointmentChangedMutation = {
  notifyAppointmentChanged:  {
    __typename: "NotificationAppointment",
    id?: string | null,
    appointmentId?: string | null,
    merchantId?: string | null,
    entityType?: string | null,
    topic?: NotificationType | null,
    message?:  {
      __typename: "Appointment",
      id: string,
      // ulid
      startTime?: string | null,
      // startTime from the first service
      endTime?: string | null,
      // endTime from the last service
      status?: AppointmentStatus | null,
      merchant:  {
        __typename: "AppointmentMerchant",
        id: string,
        dba: string,
      },
      customer?:  {
        __typename: "AppointmentCustomer",
        id?: string | null,
        // ulid
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        birthdate?: string | null,
      } | null,
      amount?: number | null,
      note?: string | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null,
    seen?: string | null,
    event?:  {
      __typename: "DynamoDBEvent",
      id?: string | null,
      name?: string | null,
      source?: string | null,
      arn?: string | null,
    } | null,
    timestamp?: number | null,
    createdAt?: string | null,
  },
};

export type CreateCampaignMutationVariables = {
  newCampaign: CampaignCreateInput,
};

export type CreateCampaignMutation = {
  // Campaign
  createCampaign:  {
    __typename: "CreatedId",
    id: string,
  },
};

export type UpdateCampaignMutationVariables = {
  updateCampaign: CampaignUpdateInput,
};

export type UpdateCampaignMutation = {
  updateCampaign: boolean,
};

export type DeleteCampaignMutationVariables = {
  merchantId: string,
  campaignId: string,
};

export type DeleteCampaignMutation = {
  deleteCampaign: boolean,
};

export type SendCampaignTestMessageMutationVariables = {
  merchantId: string,
  channel: CampaignChannel,
  recipient: string,
  message: string,
};

export type SendCampaignTestMessageMutation = {
  sendCampaignTestMessage: boolean,
};

export type CreateReviewMutationVariables = {
  newReview?: ReviewCreateInput | null,
};

export type CreateReviewMutation = {
  // Review
  createReview:  {
    __typename: "CreatedId",
    id: string,
  },
};

export type UpdateReviewMutationVariables = {
  updateReview?: ReviewUpdateInput | null,
};

export type UpdateReviewMutation = {
  updateReview: boolean,
};

export type DeleteReviewMutationVariables = {
  merchantId: string,
  reviewId: string,
};

export type DeleteReviewMutation = {
  deleteReview: boolean,
};

export type ScheduleReviewSendingMutationVariables = {
  orderId: string,
  skipLoyalterReview?: boolean | null,
};

export type ScheduleReviewSendingMutation = {
  scheduleReviewSending: boolean,
};

export type CreateReminderMutationVariables = {
  newReminder?: ReminderCreateInput | null,
};

export type CreateReminderMutation = {
  // Reminder
  createReminder:  {
    __typename: "CreatedId",
    id: string,
  },
};

export type UpdateReminderMutationVariables = {
  updateReminder?: ReminderUpdateInput | null,
};

export type UpdateReminderMutation = {
  updateReminder: boolean,
};

export type DeleteReminderMutationVariables = {
  merchantId: string,
  reminderId: string,
};

export type DeleteReminderMutation = {
  deleteReminder: boolean,
};

export type CreateBirthdayMutationVariables = {
  newBirthday?: BirthdayCreateInput | null,
};

export type CreateBirthdayMutation = {
  // Birthday
  createBirthday:  {
    __typename: "CreatedId",
    id: string,
  },
};

export type UpdateBirthdayMutationVariables = {
  updateBirthday?: BirthdayUpdateInput | null,
};

export type UpdateBirthdayMutation = {
  updateBirthday: boolean,
};

export type DeleteBirthdayMutationVariables = {
  merchantId: string,
  birthdayId: string,
};

export type DeleteBirthdayMutation = {
  deleteBirthday: boolean,
};

export type UpdateCognitoUserAttributesMutationVariables = {
  username: string,
  attributes: Array< CognitoUserAttributeInput >,
};

export type UpdateCognitoUserAttributesMutation = {
  // Cognito users
  updateCognitoUserAttributes: boolean,
};

export type CreateAppointmentMutationVariables = {
  newAppointment: AppointmentCreateInput,
};

export type CreateAppointmentMutation = {
  // Using Appointment Table section
  createAppointment:  {
    __typename: "CreatedAppointment",
    id: string,
    merchantId: string,
  },
};

export type UpdateAppointmentMutationVariables = {
  updateAppointment: AppointmentUpdateInput,
};

export type UpdateAppointmentMutation = {
  updateAppointment: boolean,
};

export type AddAppointmentServiceMutationVariables = {
  newAppointmentService: AppointmentServiceAddInput,
};

export type AddAppointmentServiceMutation = {
  addAppointmentService:  {
    __typename: "CreatedKeys",
    PK: string,
    SK: string,
  },
};

export type UpdateAppointmentServiceMutationVariables = {
  updateAppointmentService: AppointmentServiceUpdateInput,
};

export type UpdateAppointmentServiceMutation = {
  updateAppointmentService: boolean,
};

export type DeleteAppointmentServiceMutationVariables = {
  apptId: string,
  serviceId: string,
};

export type DeleteAppointmentServiceMutation = {
  deleteAppointmentService: boolean,
};

export type GetMerchantQueryVariables = {
  id: string,
};

export type GetMerchantQuery = {
  // Using Merchant Table section
  getMerchant:  {
    __typename: "Merchant",
    id: string,
    entityType: string,
    merchantId: string,
    dba: string,
    status?: MerchantStatus | null,
    logo?: string | null,
    qrcode?: string | null,
    businessAddress?:  {
      __typename: "Address",
      name?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    businessPhone?: string | null,
    businessEmail?: string | null,
    businessHours?:  {
      __typename: "BusinessHours",
      monday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
      tuesday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
      wednesday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
      thursday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
      friday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
      saturday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
      sunday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
    } | null,
    processorMid?: string | null,
    processorName?: string | null,
    ownerName: string,
    ownerEmail?: string | null,
    ownerPhone?: string | null,
    ownerAddress?:  {
      __typename: "Address",
      name?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    checkinAppSetting?:  {
      __typename: "CheckinAppSetting",
      allowUserSelectService?: boolean | null,
      allowGuestCheckin?: boolean | null,
      allowUserSelectAppointment?: boolean | null,
      allowUserSelectTechnician?: boolean | null,
      showCustomAdvertisement?: boolean | null,
      customAdvertisementContent?: string | null,
    } | null,
    sendReviewSmsEnabled?: boolean | null,
    sendReviewSmsMode?: ReviewSendMode | null,
    sendReviewSmsDelay?: number | null,
    sendReviewTriggerStatus?: OrderStatus | null,
    reviewWebappTemplate?: string | null,
    isAssociatedToGoogle?: boolean | null,
    googleAccount?: string | null,
    googleLocation?: string | null,
    googleReviewUrl?: string | null,
    badReviewAlertRecipients?:  Array< {
      __typename: "BadAlertReviewRecipient",
      employeeId: string,
      smsEnabled: boolean,
      emailEnabled: boolean,
    } | null > | null,
    timezone?: string | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type GetMerchantLambdaQueryVariables = {
  id: string,
};

export type GetMerchantLambdaQuery = {
  getMerchantLambda:  {
    __typename: "MerchantPublic",
    id: string,
    dba: string,
    logo?: string | null,
    businessAddress?:  {
      __typename: "Address",
      name?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    businessPhone?: string | null,
    businessEmail?: string | null,
    businessHours?:  {
      __typename: "BusinessHours",
      monday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
      tuesday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
      wednesday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
      thursday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
      friday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
      saturday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
      sunday?:  {
        __typename: "WorkTimeRange",
        open?: string | null,
        close?: string | null,
      } | null,
    } | null,
  },
};

export type ListMerchantsQueryVariables = {
  limit: number,
  status: MerchantStatus,
  nextToken?: string | null,
};

export type ListMerchantsQuery = {
  listMerchants:  {
    __typename: "MerchantPage",
    merchants?:  Array< {
      __typename: "Merchant",
      id: string,
      entityType: string,
      merchantId: string,
      dba: string,
      status?: MerchantStatus | null,
      logo?: string | null,
      qrcode?: string | null,
      businessAddress?:  {
        __typename: "Address",
        name?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      businessPhone?: string | null,
      businessEmail?: string | null,
      businessHours?:  {
        __typename: "BusinessHours",
        monday?:  {
          __typename: "WorkTimeRange",
          open?: string | null,
          close?: string | null,
        } | null,
        tuesday?:  {
          __typename: "WorkTimeRange",
          open?: string | null,
          close?: string | null,
        } | null,
        wednesday?:  {
          __typename: "WorkTimeRange",
          open?: string | null,
          close?: string | null,
        } | null,
        thursday?:  {
          __typename: "WorkTimeRange",
          open?: string | null,
          close?: string | null,
        } | null,
        friday?:  {
          __typename: "WorkTimeRange",
          open?: string | null,
          close?: string | null,
        } | null,
        saturday?:  {
          __typename: "WorkTimeRange",
          open?: string | null,
          close?: string | null,
        } | null,
        sunday?:  {
          __typename: "WorkTimeRange",
          open?: string | null,
          close?: string | null,
        } | null,
      } | null,
      processorMid?: string | null,
      processorName?: string | null,
      ownerName: string,
      ownerEmail?: string | null,
      ownerPhone?: string | null,
      ownerAddress?:  {
        __typename: "Address",
        name?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      checkinAppSetting?:  {
        __typename: "CheckinAppSetting",
        allowUserSelectService?: boolean | null,
        allowGuestCheckin?: boolean | null,
        allowUserSelectAppointment?: boolean | null,
        allowUserSelectTechnician?: boolean | null,
        showCustomAdvertisement?: boolean | null,
        customAdvertisementContent?: string | null,
      } | null,
      sendReviewSmsEnabled?: boolean | null,
      sendReviewSmsMode?: ReviewSendMode | null,
      sendReviewSmsDelay?: number | null,
      sendReviewTriggerStatus?: OrderStatus | null,
      reviewWebappTemplate?: string | null,
      isAssociatedToGoogle?: boolean | null,
      googleAccount?: string | null,
      googleLocation?: string | null,
      googleReviewUrl?: string | null,
      badReviewAlertRecipients?:  Array< {
        __typename: "BadAlertReviewRecipient",
        employeeId: string,
        smsEnabled: boolean,
        emailEnabled: boolean,
      } | null > | null,
      timezone?: string | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetAccessTokenQueryVariables = {
  merchantId: string,
};

export type GetAccessTokenQuery = {
  getAccessToken:  {
    __typename: "GoogleOauthResponse",
    access_token?: string | null,
    expires_in?: number | null,
    scope?: string | null,
    token_type?: string | null,
  },
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  // Merchant Category
  getCategory:  {
    __typename: "Category",
    id: string,
    name: string,
    merchantId: string,
    rank?: number | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type ListCategoriesQueryVariables = {
  merchantId: string,
  limit: number,
  nextToken?: string | null,
};

export type ListCategoriesQuery = {
  listCategories:  {
    __typename: "CategoryPage",
    categories?:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      merchantId: string,
      rank?: number | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetServiceQueryVariables = {
  id: string,
};

export type GetServiceQuery = {
  // Merchant Service
  getService:  {
    __typename: "Service",
    id: string,
    // ulid
    name: string,
    amount: number,
    merchantId: string,
    categoryId?: string | null,
    categoryName?: string | null,
    label?: string | null,
    rank?: number | null,
    isAmountUp?: boolean | null,
    duration?: number | null,
    description?: string | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type ListServicesQueryVariables = {
  merchantId: string,
  limit: number,
  nextToken?: string | null,
};

export type ListServicesQuery = {
  listServices:  {
    __typename: "ServicePage",
    services?:  Array< {
      __typename: "Service",
      id: string,
      // ulid
      name: string,
      amount: number,
      merchantId: string,
      categoryId?: string | null,
      categoryName?: string | null,
      label?: string | null,
      rank?: number | null,
      isAmountUp?: boolean | null,
      duration?: number | null,
      description?: string | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type ListServicesLambdaQueryVariables = {
  merchantId: string,
  limit: number,
  nextToken?: string | null,
};

export type ListServicesLambdaQuery = {
  listServicesLambda:  {
    __typename: "ServicePagePublic",
    services?:  Array< {
      __typename: "ServicePublic",
      id: string,
      // ulid
      name: string,
      amount: number,
      merchantId: string,
      categoryId?: string | null,
      categoryName?: string | null,
      label?: string | null,
      rank?: number | null,
      isAmountUp?: boolean | null,
      duration?: number | null,
      description?: string | null,
      createdAt?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetDiscountQueryVariables = {
  id: string,
};

export type GetDiscountQuery = {
  // Merchant Discount
  getDiscount:  {
    __typename: "Discount",
    id: string,
    // ulid
    merchantId: string,
    code: string,
    offerUnit: OfferUnitType,
    offerAmount: number,
    description?: string | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type ListDiscountsQueryVariables = {
  merchantId: string,
  limit: number,
  nextToken?: string | null,
};

export type ListDiscountsQuery = {
  listDiscounts:  {
    __typename: "DiscountPage",
    discounts?:  Array< {
      __typename: "Discount",
      id: string,
      // ulid
      merchantId: string,
      code: string,
      offerUnit: OfferUnitType,
      offerAmount: number,
      description?: string | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetPromoQueryVariables = {
  id: string,
};

export type GetPromoQuery = {
  // Merchant Promo
  getPromo:  {
    __typename: "Promo",
    id: string,
    // ulid
    merchantId: string,
    code: string,
    offerUnit: OfferUnitType,
    offerAmount: number,
    description?: string | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type ListPromosQueryVariables = {
  merchantId: string,
  limit: number,
  nextToken?: string | null,
};

export type ListPromosQuery = {
  listPromos:  {
    __typename: "PromoPage",
    promos?:  Array< {
      __typename: "Promo",
      id: string,
      // ulid
      merchantId: string,
      code: string,
      offerUnit: OfferUnitType,
      offerAmount: number,
      description?: string | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetEmployeeQueryVariables = {
  id: string,
};

export type GetEmployeeQuery = {
  // Using Employee Table section
  getEmployee:  {
    __typename: "Employee",
    id: string,
    merchantId: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    phone?: string | null,
    username?: string | null,
    publicName?: string | null,
    qrcode?: string | null,
    address?:  {
      __typename: "Address",
      name?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    birthdate?: string | null,
    image?: string | null,
    status?: EmployeeStatus | null,
    role?: EmployeeRoleType | null,
    ratingStars?: number | null,
    available?: boolean | null,
    color?: string | null,
    noCognitoUser?: boolean | null,
    pin?: string | null,
    workHours?:  {
      __typename: "EmployeeHours",
      monday?:  {
        __typename: "EmployeeWorkTimeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      tuesday?:  {
        __typename: "EmployeeWorkTimeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      wednesday?:  {
        __typename: "EmployeeWorkTimeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      thursday?:  {
        __typename: "EmployeeWorkTimeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      friday?:  {
        __typename: "EmployeeWorkTimeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      saturday?:  {
        __typename: "EmployeeWorkTimeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      sunday?:  {
        __typename: "EmployeeWorkTimeRange",
        start?: string | null,
        end?: string | null,
      } | null,
    } | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type ListEmployeesQueryVariables = {
  merchantId: string,
  limit: number,
  nextToken?: string | null,
};

export type ListEmployeesQuery = {
  listEmployees:  {
    __typename: "EmployeePage",
    employees?:  Array< {
      __typename: "Employee",
      id: string,
      merchantId: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      username?: string | null,
      publicName?: string | null,
      qrcode?: string | null,
      address?:  {
        __typename: "Address",
        name?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      birthdate?: string | null,
      image?: string | null,
      status?: EmployeeStatus | null,
      role?: EmployeeRoleType | null,
      ratingStars?: number | null,
      available?: boolean | null,
      color?: string | null,
      noCognitoUser?: boolean | null,
      pin?: string | null,
      workHours?:  {
        __typename: "EmployeeHours",
        monday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
        tuesday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
        wednesday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
        thursday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
        friday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
        saturday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
        sunday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
      } | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type ListEmployeesLambdaQueryVariables = {
  merchantId: string,
  limit: number,
  nextToken?: string | null,
};

export type ListEmployeesLambdaQuery = {
  listEmployeesLambda:  {
    __typename: "EmployeePagePublic",
    employees?:  Array< {
      __typename: "EmployeePublic",
      id: string,
      merchantId: string,
      firstName?: string | null,
      publicName?: string | null,
      workHours?:  {
        __typename: "EmployeeHours",
        monday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
        tuesday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
        wednesday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
        thursday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
        friday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
        saturday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
        sunday?:  {
          __typename: "EmployeeWorkTimeRange",
          start?: string | null,
          end?: string | null,
        } | null,
      } | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetCustomerQueryVariables = {
  id: string,
};

export type GetCustomerQuery = {
  // Using Customer Table section
  getCustomer:  {
    __typename: "Customer",
    id: string,
    merchantId: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    phone?: string | null,
    username?: string | null,
    publicName?: string | null,
    qrcode?: string | null,
    address?:  {
      __typename: "Address",
      name?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    birthdate?: string | null,
    image?: string | null,
    status?: CustomerStatus | null,
    type?: CustomerType | null,
    rewardPoints?: number | null,
    visits?: number | null,
    note?: string | null,
    tags?: Array< string > | null,
    receiveSms?: boolean | null,
    receiveEmail?: boolean | null,
    disableReviewSms?: boolean | null,
    lastReviewOpenedAt?: string | null,
    googleReviewButtonClickedAt?: string | null,
    googleReviewCreatedAt?: string | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type GetCustomerByPhoneQueryVariables = {
  merchantId: string,
  phone: string,
};

export type GetCustomerByPhoneQuery = {
  getCustomerByPhone:  {
    __typename: "Customer",
    id: string,
    merchantId: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    phone?: string | null,
    username?: string | null,
    publicName?: string | null,
    qrcode?: string | null,
    address?:  {
      __typename: "Address",
      name?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    birthdate?: string | null,
    image?: string | null,
    status?: CustomerStatus | null,
    type?: CustomerType | null,
    rewardPoints?: number | null,
    visits?: number | null,
    note?: string | null,
    tags?: Array< string > | null,
    receiveSms?: boolean | null,
    receiveEmail?: boolean | null,
    disableReviewSms?: boolean | null,
    lastReviewOpenedAt?: string | null,
    googleReviewButtonClickedAt?: string | null,
    googleReviewCreatedAt?: string | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type GetCustomerByPhoneLambdaQueryVariables = {
  merchantId: string,
  phone: string,
};

export type GetCustomerByPhoneLambdaQuery = {
  getCustomerByPhoneLambda:  {
    __typename: "CustomerPublic",
    id: string,
    merchantId: string,
    firstName: string,
    lastName: string,
    phone?: string | null,
    publicName?: string | null,
    createdAt?: string | null,
  },
};

export type ListCustomersQueryVariables = {
  merchantId: string,
  customerType?: CustomerType | null,
  limit: number,
  nextToken?: string | null,
};

export type ListCustomersQuery = {
  listCustomers:  {
    __typename: "CustomerPage",
    customers?:  Array< {
      __typename: "Customer",
      id: string,
      merchantId: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      username?: string | null,
      publicName?: string | null,
      qrcode?: string | null,
      address?:  {
        __typename: "Address",
        name?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      birthdate?: string | null,
      image?: string | null,
      status?: CustomerStatus | null,
      type?: CustomerType | null,
      rewardPoints?: number | null,
      visits?: number | null,
      note?: string | null,
      tags?: Array< string > | null,
      receiveSms?: boolean | null,
      receiveEmail?: boolean | null,
      disableReviewSms?: boolean | null,
      lastReviewOpenedAt?: string | null,
      googleReviewButtonClickedAt?: string | null,
      googleReviewCreatedAt?: string | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type SearchCustomersQueryVariables = {
  merchantId: string,
  query: string,
  limit: number,
  filters: SearchCustomersFilters,
  options?: SearchCustomersQueryOptions | null,
};

export type SearchCustomersQuery = {
  searchCustomers:  {
    __typename: "CustomerSearchResult",
    customers?:  Array< {
      __typename: "Customer",
      id: string,
      merchantId: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      username?: string | null,
      publicName?: string | null,
      qrcode?: string | null,
      address?:  {
        __typename: "Address",
        name?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      birthdate?: string | null,
      image?: string | null,
      status?: CustomerStatus | null,
      type?: CustomerType | null,
      rewardPoints?: number | null,
      visits?: number | null,
      note?: string | null,
      tags?: Array< string > | null,
      receiveSms?: boolean | null,
      receiveEmail?: boolean | null,
      disableReviewSms?: boolean | null,
      lastReviewOpenedAt?: string | null,
      googleReviewButtonClickedAt?: string | null,
      googleReviewCreatedAt?: string | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    total: number,
    queryId?: string | null,
    startCursor?: string | null,
    nextCursor?: string | null,
  },
};

export type ValidateCustomerQueryVariables = {
  merchantId: string,
  fieldType: CustomerValidationType,
  fieldValue: string,
};

export type ValidateCustomerQuery = {
  validateCustomer: boolean,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  // Using Order Table section
  getOrder:  {
    __typename: "Order",
    id: string,
    // ulid
    amount: number,
    tenderType?: TenderType | null,
    status: OrderStatus,
    merchant:  {
      __typename: "OrderMerchant",
      id: string,
      dba: string,
    },
    customer?:  {
      __typename: "OrderCustomer",
      id?: string | null,
      merchantId?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      publicName?: string | null,
      qrcode?: string | null,
      address?:  {
        __typename: "Address",
        name?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      birthdate?: string | null,
      image?: string | null,
      status?: CustomerStatus | null,
      type?: CustomerType | null,
      rewardPoints?: number | null,
      visits?: number | null,
      note?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    note?: string | null,
    number?: string | null,
    appointment?: string | null,
    cardInfo?: string | null,
    reviewLinkCreationDate?: string | null,
    reviewSentDate?: string | null,
    reviewSentType?: ReviewSentType | null,
    reviewId?: string | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type GetOrderDetailQueryVariables = {
  id: string,
};

export type GetOrderDetailQuery = {
  getOrderDetail:  {
    __typename: "OrderDetail",
    id: string,
    // ulid
    amount: number,
    tenderType?: TenderType | null,
    status: OrderStatus,
    merchant:  {
      __typename: "OrderMerchant",
      id: string,
      dba: string,
    },
    customer?:  {
      __typename: "OrderCustomer",
      id?: string | null,
      merchantId?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      publicName?: string | null,
      qrcode?: string | null,
      address?:  {
        __typename: "Address",
        name?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      birthdate?: string | null,
      image?: string | null,
      status?: CustomerStatus | null,
      type?: CustomerType | null,
      rewardPoints?: number | null,
      visits?: number | null,
      note?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    note?: string | null,
    number?: string | null,
    appointment?: string | null,
    cardInfo?: string | null,
    reviewLinkCreationDate?: string | null,
    reviewSentDate?: string | null,
    reviewSentType?: ReviewSentType | null,
    reviewId?: string | null,
    services?:  Array< {
      __typename: "OrderService",
      id: string,
      name: string,
      amount: number,
      categoryId?: string | null,
      categoryName?: string | null,
      label?: string | null,
      rank?: number | null,
      tenderType?: TenderType | null,
      employeeId?: string | null,
      employeeName?: string | null,
    } | null > | null,
    promos?:  Array< {
      __typename: "OrderPromo",
      id: string,
      code: string,
      offerUnit: OfferUnitType,
      offerAmount: number,
      amount: number,
      description?: string | null,
    } | null > | null,
    discounts?:  Array< {
      __typename: "OrderDiscount",
      id: string,
      code: string,
      offerUnit: OfferUnitType,
      offerAmount: number,
      amount: number,
      description?: string | null,
    } | null > | null,
    redeems?:  Array< {
      __typename: "OrderRedeem",
      id: string,
      // ulid
      points: number,
      amount?: number | null,
    } | null > | null,
    taxes?:  Array< {
      __typename: "OrderTax",
      id: string,
      code: string,
      offerUnit: OfferUnitType,
      offerAmount: number,
      amount: number,
      description?: string | null,
    } | null > | null,
    tips?:  Array< {
      __typename: "OrderTip",
      id: string,
      code?: string | null,
      offerUnit: OfferUnitType,
      offerAmount: number,
      amount: number,
      description?: string | null,
      tenderType?: TipTenderType | null,
      employeeId?: string | null,
      employeeName?: string | null,
    } | null > | null,
    transactions?:  Array< {
      __typename: "OrderTransaction",
      id: string,
      mid: string,
      amount: number,
      status: TransactionStatus,
      paymentType: PaymentType,
      processor: string,
    } | null > | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type ListOrdersQueryVariables = {
  merchantId: string,
  startDate?: string | null,
  endDate?: string | null,
  statusFilter?: OrderStatusFilter | null,
  limit: number,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders:  {
    __typename: "OrderPage",
    orders?:  Array< {
      __typename: "Order",
      id: string,
      // ulid
      amount: number,
      tenderType?: TenderType | null,
      status: OrderStatus,
      merchant:  {
        __typename: "OrderMerchant",
        id: string,
        dba: string,
      },
      customer?:  {
        __typename: "OrderCustomer",
        id?: string | null,
        merchantId?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        publicName?: string | null,
        qrcode?: string | null,
        address?:  {
          __typename: "Address",
          name?: string | null,
          street1?: string | null,
          street2?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        birthdate?: string | null,
        image?: string | null,
        status?: CustomerStatus | null,
        type?: CustomerType | null,
        rewardPoints?: number | null,
        visits?: number | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      note?: string | null,
      number?: string | null,
      appointment?: string | null,
      cardInfo?: string | null,
      reviewLinkCreationDate?: string | null,
      reviewSentDate?: string | null,
      reviewSentType?: ReviewSentType | null,
      reviewId?: string | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type ListOrdersByCustomerQueryVariables = {
  customerId: string,
  startDate?: string | null,
  endDate?: string | null,
  limit: number,
  nextToken?: string | null,
};

export type ListOrdersByCustomerQuery = {
  listOrdersByCustomer:  {
    __typename: "OrderPage",
    orders?:  Array< {
      __typename: "Order",
      id: string,
      // ulid
      amount: number,
      tenderType?: TenderType | null,
      status: OrderStatus,
      merchant:  {
        __typename: "OrderMerchant",
        id: string,
        dba: string,
      },
      customer?:  {
        __typename: "OrderCustomer",
        id?: string | null,
        merchantId?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        publicName?: string | null,
        qrcode?: string | null,
        address?:  {
          __typename: "Address",
          name?: string | null,
          street1?: string | null,
          street2?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        birthdate?: string | null,
        image?: string | null,
        status?: CustomerStatus | null,
        type?: CustomerType | null,
        rewardPoints?: number | null,
        visits?: number | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      note?: string | null,
      number?: string | null,
      appointment?: string | null,
      cardInfo?: string | null,
      reviewLinkCreationDate?: string | null,
      reviewSentDate?: string | null,
      reviewSentType?: ReviewSentType | null,
      reviewId?: string | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type SearchOrdersQueryVariables = {
  merchantId: string,
  query?: string | null,
  page: number,
  limit: number,
  startDate?: string | null,
  endDate?: string | null,
  status?: OrderStatusFilter | null,
};

export type SearchOrdersQuery = {
  searchOrders:  {
    __typename: "OrderSearchResult",
    orders?:  Array< {
      __typename: "OrderDetail",
      id: string,
      // ulid
      amount: number,
      tenderType?: TenderType | null,
      status: OrderStatus,
      merchant:  {
        __typename: "OrderMerchant",
        id: string,
        dba: string,
      },
      customer?:  {
        __typename: "OrderCustomer",
        id?: string | null,
        merchantId?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        publicName?: string | null,
        qrcode?: string | null,
        address?:  {
          __typename: "Address",
          name?: string | null,
          street1?: string | null,
          street2?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        birthdate?: string | null,
        image?: string | null,
        status?: CustomerStatus | null,
        type?: CustomerType | null,
        rewardPoints?: number | null,
        visits?: number | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      note?: string | null,
      number?: string | null,
      appointment?: string | null,
      cardInfo?: string | null,
      reviewLinkCreationDate?: string | null,
      reviewSentDate?: string | null,
      reviewSentType?: ReviewSentType | null,
      reviewId?: string | null,
      services?:  Array< {
        __typename: "OrderService",
        id: string,
        name: string,
        amount: number,
        categoryId?: string | null,
        categoryName?: string | null,
        label?: string | null,
        rank?: number | null,
        tenderType?: TenderType | null,
        employeeId?: string | null,
        employeeName?: string | null,
      } | null > | null,
      promos?:  Array< {
        __typename: "OrderPromo",
        id: string,
        code: string,
        offerUnit: OfferUnitType,
        offerAmount: number,
        amount: number,
        description?: string | null,
      } | null > | null,
      discounts?:  Array< {
        __typename: "OrderDiscount",
        id: string,
        code: string,
        offerUnit: OfferUnitType,
        offerAmount: number,
        amount: number,
        description?: string | null,
      } | null > | null,
      redeems?:  Array< {
        __typename: "OrderRedeem",
        id: string,
        // ulid
        points: number,
        amount?: number | null,
      } | null > | null,
      taxes?:  Array< {
        __typename: "OrderTax",
        id: string,
        code: string,
        offerUnit: OfferUnitType,
        offerAmount: number,
        amount: number,
        description?: string | null,
      } | null > | null,
      tips?:  Array< {
        __typename: "OrderTip",
        id: string,
        code?: string | null,
        offerUnit: OfferUnitType,
        offerAmount: number,
        amount: number,
        description?: string | null,
        tenderType?: TipTenderType | null,
        employeeId?: string | null,
        employeeName?: string | null,
      } | null > | null,
      transactions?:  Array< {
        __typename: "OrderTransaction",
        id: string,
        mid: string,
        amount: number,
        status: TransactionStatus,
        paymentType: PaymentType,
        processor: string,
      } | null > | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    total: number,
  },
};

export type GetStatisticsQueryVariables = {
  merchantId: string,
  statisticType?: StatisticType | null,
  startDate?: string | null,
  endDate?: string | null,
  limit: number,
  nextToken?: string | null,
};

export type GetStatisticsQuery = {
  // Using Statistic Table section
  // DDB as sources
  getStatistics:  {
    __typename: "StatisticPage",
    statistics?:  Array< {
      __typename: "Statistic",
      PK: string,
      // PK MERCHANT#id
      SK: string,
      // SK in form [SUM, WEEK#startDate, MONTH#startDate, YEAR#startDate, DATE#startDate]  startDate in form YYYY-MM-DD local time
      id: string,
      // combination of PK and SK [MERCHANT#mid#DATE#startDate]
      startDate: string,
      // YYYY-MM-DD
      entityType?: string | null,
      // [SUM, MONDAY...SUNDAY, WEEK1...WEEK52, MONTH1...MONTH12, YEAR]
      merchantId: string,
      cardAmount?: number | null,
      cardTipAmount?: number | null,
      cardTotal?: number | null,
      cashAmount?: number | null,
      cashTipAmount?: number | null,
      cashTotal?: number | null,
      allTotal?: number | null,
      orderCounts?: number | null,
      orderNumberCounter?: number | null,
      customerCounts?: number | null,
      employeeCounts?: number | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetOrderStatisticsQueryVariables = {
  merchantId: string,
  groupDateType: groupDateType,
  startDate: string,
  endDate: string,
};

export type GetOrderStatisticsQuery = {
  // Rockset as sources
  getOrderStatistics:  {
    __typename: "OrderStatisticsResult",
    merchantId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    groupDateType?: groupDateType | null,
    results?:  Array< {
      __typename: "OrderStatistics",
      PK?: string | null,
      // PK MERCHANT#id
      SK?: string | null,
      // SK in form [ORDER_STATS#WEEK#groupDate, ORDER_STATS#MONTH#groupDate, ORDER_STATS#YEAR#groupDate, ORDER_STATS#DAY#groupDate]  groupDate in form YYYY-MM-DD local time
      id?: string | null,
      // combination of PK and SK [MERCHANT#mid#ORDER_STATS#DAY#groupDate]
      entityType?: string | null,
      // [ORDER_STATS, REVIEW_STATS, CUSTOMER_STATS, EMPLOYEE_STATS]
      dateType?: string | null,
      // [MONDAY...SUNDAY, WEEK01...WEEK52, MONTH01...MONTH12, YEAR2022]
      merchantId: string,
      groupDate: string,
      groupDateType: groupDateType,
      cardOrderAmount?: number | null,
      cardTipAmount?: number | null,
      cardTotal?: number | null,
      cashOrderAmount?: number | null,
      cashTipAmount?: number | null,
      cashTotal?: number | null,
      cashCounts?: number | null,
      cardCounts?: number | null,
      tipCounts?: number | null,
      allCounts?: number | null,
      tipTotal?: number | null,
      allTotal?: number | null,
      orderCounts?: number | null,
      // will deprecate when refactor merchant app
      highestOrderAmount?: number | null,
      lowestOrderAmount?: number | null,
      averageOrderAmount?: number | null,
      highestTipAmount?: number | null,
      lowestTipAmount?: number | null,
      averageTipAmount?: number | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
  },
};

export type GetEmployeeStatisticsQueryVariables = {
  merchantId: string,
  groupDateType: groupDateType,
  startDate: string,
  endDate: string,
};

export type GetEmployeeStatisticsQuery = {
  getEmployeeStatistics:  {
    __typename: "EmployeeStatisticsResult",
    merchantId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    groupDateType?: groupDateType | null,
    results?:  Array< {
      __typename: "EmployeeStatistics",
      PK?: string | null,
      // PK MERCHANT#id
      SK?: string | null,
      // SK in form [EMPLOYEE_STATS#WEEK#groupDate, EMPLOYEE_STATS#MONTH#groupDate, EMPLOYEE_STATS#YEAR#groupDate, EMPLOYEE_STATS#DAY#groupDate]  groupDate in form YYYY-MM-DD local time
      id?: string | null,
      // combination of PK and SK [MERCHANT#mid#EMPLOYEE_STATS#DAY#groupDate]
      entityType?: string | null,
      // [EMPLOYEE_STATS, REVIEW_STATS, CUSTOMER_STATS, EMPLOYEE_STATS]
      dateType?: string | null,
      // [MONDAY...SUNDAY, WEEK01...WEEK52, MONTH01...MONTH12, YEAR2022]
      merchantId: string,
      employeeId?: string | null,
      employeeName?: string | null,
      groupDate: string,
      groupDateType: groupDateType,
      serviceAllCounts?: number | null,
      // will deprecate when refactor merchant app
      serviceCardCounts?: number | null,
      serviceCashCounts?: number | null,
      serviceCardAmount?: number | null,
      serviceCashAmount?: number | null,
      serviceTotal?: number | null,
      tipAllCounts?: number | null,
      tipCardCounts?: number | null,
      tipCashCounts?: number | null,
      tipCardAmount?: number | null,
      tipCashAmount?: number | null,
      tipTotal?: number | null,
      cardTotal?: number | null,
      cashTotal?: number | null,
      allTotal?: number | null,
      highestServiceAmount?: number | null,
      lowestServiceAmount?: number | null,
      averageServiceAmount?: number | null,
      highestTipAmount?: number | null,
      lowestTipAmount?: number | null,
      averageTipAmount?: number | null,
      summary?:  Array< {
        __typename: "EmployeeStatisticsSummaryItem",
        PK?: string | null,
        // ulid
        SK?: string | null,
        entityType?: string | null,
        name?: string | null,
        amount?: number | null,
        tenderType?: TenderType | null,
        status?: OrderStatus | null,
        customerId?: string | null,
        customerName?: string | null,
        createdAt?: string | null,
      } > | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
  },
};

export type GetCustomerTypeCountsQueryVariables = {
  merchantId: string,
};

export type GetCustomerTypeCountsQuery = {
  getCustomerTypeCounts:  {
    __typename: "CustomerTypeCountsResult",
    merchantId?: string | null,
    results?:  Array< {
      __typename: "CustomerTypeCountsItem",
      customerType?: CustomerType | null,
      counts?: number | null,
    } | null > | null,
  },
};

export type GetReviewStatisticsQueryVariables = {
  merchantId: string,
  reviewType: ReviewType,
};

export type GetReviewStatisticsQuery = {
  getReviewStatistics:  {
    __typename: "ReviewStatisticsResult",
    merchantId?: string | null,
    reviewType?: ReviewType | null,
    results?:  Array< {
      __typename: "ReviewStatistics",
      merchantId?: string | null,
      reviewType?: ReviewType | null,
      averageRating?: number | null,
      reviewCounts?: number | null,
    } | null > | null,
  },
};

export type ListCampaignsQueryVariables = {
  merchantId: string,
  limit: number,
  nextToken?: string | null,
};

export type ListCampaignsQuery = {
  // Campaign
  listCampaigns:  {
    __typename: "CampaignPage",
    campaigns?:  Array< {
      __typename: "Campaign",
      merchantId: string,
      dba?: string | null,
      id: string,
      name?: string | null,
      channel?: CampaignChannel | null,
      type: CampaignType,
      status?: CampaignStatus | null,
      frequency?: CampaignFrequency | null,
      startDate?: string | null,
      endDate?: string | null,
      promoId?: string | null,
      promoCode?: string | null,
      promoOfferUnit?: OfferUnitType | null,
      promoAmount?: number | null,
      promoDescription?: string | null,
      templateMessage?: string | null,
      rules?:  Array< {
        __typename: "CampaignRule",
        name?: string | null,
        entityType: string,
        fieldPath: string,
        fieldType: CampaignRuleFieldType,
        valueType: CampaignRuleValueType,
        operation: CampaignRuleOperation,
        value: string,
      } | null > | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetCampaignQueryVariables = {
  merchantId: string,
  campaignId: string,
};

export type GetCampaignQuery = {
  getCampaign:  {
    __typename: "Campaign",
    merchantId: string,
    dba?: string | null,
    id: string,
    name?: string | null,
    channel?: CampaignChannel | null,
    type: CampaignType,
    status?: CampaignStatus | null,
    frequency?: CampaignFrequency | null,
    startDate?: string | null,
    endDate?: string | null,
    promoId?: string | null,
    promoCode?: string | null,
    promoOfferUnit?: OfferUnitType | null,
    promoAmount?: number | null,
    promoDescription?: string | null,
    templateMessage?: string | null,
    rules?:  Array< {
      __typename: "CampaignRule",
      name?: string | null,
      entityType: string,
      fieldPath: string,
      fieldType: CampaignRuleFieldType,
      valueType: CampaignRuleValueType,
      operation: CampaignRuleOperation,
      value: string,
    } | null > | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type GetReviewQueryVariables = {
  merchantId: string,
  reviewId: string,
};

export type GetReviewQuery = {
  // Review
  getReview?:  {
    __typename: "Review",
    merchantId: string,
    id: string,
    // ulid
    type?: ReviewType | null,
    orderId: string,
    customerId: string,
    customerFirstName?: string | null,
    customerLastName?: string | null,
    customerEmail?: string | null,
    customerPhone?: string | null,
    message?: string | null,
    orderRating?: number | null,
    serviceRatings?:  Array< {
      __typename: "ServiceReviewRating",
      id: string,
      name: string,
      amount: number,
      categoryId?: string | null,
      categoryName?: string | null,
      label?: string | null,
      rank?: number | null,
      employeeId?: string | null,
      employeeName?: string | null,
      rating: number,
    } | null > | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListReviewsQueryVariables = {
  merchantId: string,
  limit: number,
  nextToken?: string | null,
};

export type ListReviewsQuery = {
  listReviews:  {
    __typename: "ReviewPage",
    reviews?:  Array< {
      __typename: "Review",
      merchantId: string,
      id: string,
      // ulid
      type?: ReviewType | null,
      orderId: string,
      customerId: string,
      customerFirstName?: string | null,
      customerLastName?: string | null,
      customerEmail?: string | null,
      customerPhone?: string | null,
      message?: string | null,
      orderRating?: number | null,
      serviceRatings?:  Array< {
        __typename: "ServiceReviewRating",
        id: string,
        name: string,
        amount: number,
        categoryId?: string | null,
        categoryName?: string | null,
        label?: string | null,
        rank?: number | null,
        employeeId?: string | null,
        employeeName?: string | null,
        rating: number,
      } | null > | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetReminderQueryVariables = {
  merchantId: string,
  reminderId: string,
};

export type GetReminderQuery = {
  // Reminder
  getReminder?:  {
    __typename: "Reminder",
    id: string,
    merchantId: string,
    promotionId: string,
    threshold: number,
    totalDelivered: number,
    customerTypes: Array< CustomerType | null >,
    reminderStatus: ReminderStatus,
    message: string,
  } | null,
};

export type ListRemindersQueryVariables = {
  merchantId: string,
  limit: number,
  nextToken?: string | null,
};

export type ListRemindersQuery = {
  listReminders:  {
    __typename: "ReminderPage",
    reminders?:  Array< {
      __typename: "Reminder",
      id: string,
      merchantId: string,
      promotionId: string,
      threshold: number,
      totalDelivered: number,
      customerTypes: Array< CustomerType | null >,
      reminderStatus: ReminderStatus,
      message: string,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetBirthdayQueryVariables = {
  merchantId: string,
  birthdayId: string,
};

export type GetBirthdayQuery = {
  // Birthday
  getBirthday?:  {
    __typename: "Birthday",
    id: string,
    merchantId: string,
    promotionId: string,
    threshold: number,
    totalDelivered: number,
    customerTypes: Array< CustomerType | null >,
    reminderStatus: ReminderStatus,
    message: string,
  } | null,
};

export type ListBirthdaysQueryVariables = {
  merchantId: string,
  limit: number,
  nextToken?: string | null,
};

export type ListBirthdaysQuery = {
  listBirthdays:  {
    __typename: "BirthdayPage",
    birthdays?:  Array< {
      __typename: "Birthday",
      id: string,
      merchantId: string,
      promotionId: string,
      threshold: number,
      totalDelivered: number,
      customerTypes: Array< CustomerType | null >,
      reminderStatus: ReminderStatus,
      message: string,
    } > | null,
    nextToken?: string | null,
  },
};

export type ListCognitoUsersQueryVariables = {
  limit: number,
  nextToken?: string | null,
};

export type ListCognitoUsersQuery = {
  // Cognito users
  listCognitoUsers:  {
    __typename: "CognitoUserPage",
    users?:  Array< {
      __typename: "CognitoUser",
      username: string,
      attributes?:  Array< {
        __typename: "CognitoUserAttribute",
        name: string,
        value?: string | null,
      } > | null,
      createDate?: string | null,
      lastModifiedDate?: string | null,
      enabled: boolean,
      status: CognitoUserStatus,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetCognitoUserQueryVariables = {
  username: string,
};

export type GetCognitoUserQuery = {
  getCognitoUser?:  {
    __typename: "CognitoUser",
    username: string,
    attributes?:  Array< {
      __typename: "CognitoUserAttribute",
      name: string,
      value?: string | null,
    } > | null,
    createDate?: string | null,
    lastModifiedDate?: string | null,
    enabled: boolean,
    status: CognitoUserStatus,
  } | null,
};

export type GetAppointmentQueryVariables = {
  id: string,
};

export type GetAppointmentQuery = {
  // Using Appointment Table section
  getAppointment:  {
    __typename: "Appointment",
    id: string,
    // ulid
    startTime?: string | null,
    // startTime from the first service
    endTime?: string | null,
    // endTime from the last service
    status?: AppointmentStatus | null,
    merchant:  {
      __typename: "AppointmentMerchant",
      id: string,
      dba: string,
    },
    customer?:  {
      __typename: "AppointmentCustomer",
      id?: string | null,
      // ulid
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      birthdate?: string | null,
    } | null,
    amount?: number | null,
    note?: string | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type GetAppointmentDetailQueryVariables = {
  id: string,
};

export type GetAppointmentDetailQuery = {
  getAppointmentDetail:  {
    __typename: "AppointmentDetail",
    id: string,
    // ulid
    startTime?: string | null,
    // startTime from the first service
    endTime?: string | null,
    // endTime from the last service
    status?: AppointmentStatus | null,
    merchant:  {
      __typename: "AppointmentMerchant",
      id: string,
      dba: string,
    },
    customer?:  {
      __typename: "AppointmentCustomer",
      id?: string | null,
      // ulid
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      birthdate?: string | null,
    } | null,
    amount?: number | null,
    note?: string | null,
    services?:  Array< {
      __typename: "AppointmentService",
      id: string,
      startTime?: string | null,
      endTime?: string | null,
      name: string,
      amount: number,
      categoryId?: string | null,
      categoryName?: string | null,
      employeeId?: string | null,
      employeeName?: string | null,
    } | null > | null,
    timestamp?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  },
};

export type ListAppointmentsQueryVariables = {
  merchantId: string,
  startTime?: string | null,
  endTime?: string | null,
  employeeFilter?: string | null,
  customerPhoneFilter?: string | null,
  status?: AppointmentStatus | null,
};

export type ListAppointmentsQuery = {
  listAppointments:  {
    __typename: "AppointmentDetailPage",
    appointments?:  Array< {
      __typename: "AppointmentDetail",
      id: string,
      // ulid
      startTime?: string | null,
      // startTime from the first service
      endTime?: string | null,
      // endTime from the last service
      status?: AppointmentStatus | null,
      merchant:  {
        __typename: "AppointmentMerchant",
        id: string,
        dba: string,
      },
      customer?:  {
        __typename: "AppointmentCustomer",
        id?: string | null,
        // ulid
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        birthdate?: string | null,
      } | null,
      amount?: number | null,
      note?: string | null,
      services?:  Array< {
        __typename: "AppointmentService",
        id: string,
        startTime?: string | null,
        endTime?: string | null,
        name: string,
        amount: number,
        categoryId?: string | null,
        categoryName?: string | null,
        employeeId?: string | null,
        employeeName?: string | null,
      } | null > | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } > | null,
  },
};

export type ListAppointmentsLambdaQueryVariables = {
  merchantId: string,
  startTime?: string | null,
  endTime?: string | null,
  employeeFilter?: string | null,
  customerPhoneFilter?: string | null,
};

export type ListAppointmentsLambdaQuery = {
  listAppointmentsLambda:  {
    __typename: "AppointmentDetailPagePublic",
    appointments?:  Array< {
      __typename: "AppointmentDetailPublic",
      id: string,
      // ulid
      startTime?: string | null,
      // startTime from the first service
      endTime?: string | null,
      // endTime from the last service
      status?: AppointmentStatus | null,
      merchant:  {
        __typename: "AppointmentMerchant",
        id: string,
        dba: string,
      },
      services?:  Array< {
        __typename: "AppointmentServicePublic",
        id: string,
        startTime?: string | null,
        endTime?: string | null,
        name: string,
        categoryId?: string | null,
        categoryName?: string | null,
      } | null > | null,
      createdAt?: string | null,
    } > | null,
  },
};

export type GetEmployeeIncomeReportQueryVariables = {
  merchantId: string,
  employeeFilter?: string | null,
  dateLabel?: string | null,
  startDate: string,
  endDate: string,
};

export type GetEmployeeIncomeReportQuery = {
  // Reports
  getEmployeeIncomeReport:  {
    __typename: "EmployeeIncomeReportResult",
    incomeReports?:  Array< {
      __typename: "EmployeeIncomeDetailReport",
      employeeId?: string | null,
      employeeName?: string | null,
      employeeColor?: string | null,
      merchantId: string,
      startDate?: string | null,
      endDate?: string | null,
      dateLabel?: string | null,
      revenue?: number | null,
      commission?: number | null,
      tip?: number | null,
      Income?: number | null,
      cashIncome?: number | null,
      checkIncome?: number | null,
      detailByDay?:  Array< {
        __typename: "EmployeeServiceReportByDay",
        orderId?: string | null,
        orderNumber?: string | null,
        dateTime?: string | null,
        serviceName?: string | null,
        serviceAmount?: number | null,
        tipAmount?: number | null,
      } > | null,
      detailByDateRange?:  Array< {
        __typename: "EmployeeServiceReportByDateRange",
        dateTime?: string | null,
        orderCounts?: number | null,
        totalServiceAmount?: number | null,
        totalTipAmount?: number | null,
      } > | null,
    } > | null,
  },
};

export type OnNotifyOrderChangedSubscriptionVariables = {
  merchantId: string,
};

export type OnNotifyOrderChangedSubscription = {
  onNotifyOrderChanged?:  {
    __typename: "NotificationOrder",
    id?: string | null,
    orderId?: string | null,
    merchantId?: string | null,
    entityType?: string | null,
    topic?: NotificationType | null,
    message?:  {
      __typename: "Order",
      id: string,
      // ulid
      amount: number,
      tenderType?: TenderType | null,
      status: OrderStatus,
      merchant:  {
        __typename: "OrderMerchant",
        id: string,
        dba: string,
      },
      customer?:  {
        __typename: "OrderCustomer",
        id?: string | null,
        merchantId?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        publicName?: string | null,
        qrcode?: string | null,
        address?:  {
          __typename: "Address",
          name?: string | null,
          street1?: string | null,
          street2?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        birthdate?: string | null,
        image?: string | null,
        status?: CustomerStatus | null,
        type?: CustomerType | null,
        rewardPoints?: number | null,
        visits?: number | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      note?: string | null,
      number?: string | null,
      appointment?: string | null,
      cardInfo?: string | null,
      reviewLinkCreationDate?: string | null,
      reviewSentDate?: string | null,
      reviewSentType?: ReviewSentType | null,
      reviewId?: string | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null,
    seen?: string | null,
    event?:  {
      __typename: "DynamoDBEvent",
      id?: string | null,
      name?: string | null,
      source?: string | null,
      arn?: string | null,
    } | null,
    timestamp?: number | null,
    createdAt?: string | null,
  } | null,
};

export type OnNotifyAppointmentChangedSubscriptionVariables = {
  merchantId: string,
};

export type OnNotifyAppointmentChangedSubscription = {
  onNotifyAppointmentChanged?:  {
    __typename: "NotificationAppointment",
    id?: string | null,
    appointmentId?: string | null,
    merchantId?: string | null,
    entityType?: string | null,
    topic?: NotificationType | null,
    message?:  {
      __typename: "Appointment",
      id: string,
      // ulid
      startTime?: string | null,
      // startTime from the first service
      endTime?: string | null,
      // endTime from the last service
      status?: AppointmentStatus | null,
      merchant:  {
        __typename: "AppointmentMerchant",
        id: string,
        dba: string,
      },
      customer?:  {
        __typename: "AppointmentCustomer",
        id?: string | null,
        // ulid
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phone?: string | null,
        birthdate?: string | null,
      } | null,
      amount?: number | null,
      note?: string | null,
      timestamp?: number | null,
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null,
    seen?: string | null,
    event?:  {
      __typename: "DynamoDBEvent",
      id?: string | null,
      name?: string | null,
      source?: string | null,
      arn?: string | null,
    } | null,
    timestamp?: number | null,
    createdAt?: string | null,
  } | null,
};
