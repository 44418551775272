import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'reactstrap';

const Layout: React.FunctionComponent = () => {
    return (
        <Container style={{ paddingTop: '5vh' }}>
            <Outlet />
        </Container>
    );
};

export default Layout;